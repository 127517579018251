import { ModalButtons, ModalResult } from './message-box';
import type { AbstractControl, FormGroup } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import type { XapiErrorService } from '@office/xapi-error.service';
import { EMPTY, type Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

export type DialogOutput<TPayload = never, TCustomPayload = never> =
    { decision: ModalResult.Cancel } |
    { decision: ModalResult.Ok; payload?: TPayload } |
    { decision: ModalResult.Custom; payload?: TCustomPayload };

/** Base class for components, that are shown in dialog via ModalService */
export class DialogComponent<TPayload = never, TCustomPayload = never> {
    buttons: ModalButtons = ModalButtons.Ok;
    output: DialogOutput<TPayload, TCustomPayload> = { decision: ModalResult.Cancel };
    showValidation = false;

    /** Factory-method for validateBeforeSubmit, based upon edited control / form */
    createDialogValidate(control: AbstractControl, cd: ChangeDetectorRef) {
        return () => {
            if (control.invalid) {
                this.showValidation = true;
                control.markAllAsTouched();
                cd.markForCheck();
                return false;
            }
            return true;
        };
    }

    onClose(decision: ModalResult) {
        this.output = { decision };
    }

    onSubmit() {
        this.output = { decision: ModalResult.Ok, payload: this.getPayload() };
    }

    onCustom() {
        this.output = { decision: ModalResult.Custom, payload: this.getCustomPayload() };
    }

    /** Compute output payload when ok button is pressed */
    protected getPayload(): TPayload | undefined {
        return undefined as never;
    }

    /** Compute custom output payload when custom button is pressed */
    protected getCustomPayload(): TCustomPayload {
        return undefined as never;
    }

    protected handleError = (xapiError: XapiErrorService, cd: ChangeDetectorRef, form: FormGroup, camelize = false) => <T>(source: Observable<T>) =>
        source.pipe(
            catchError(err => {
                if (!xapiError.error(cd, err, form, camelize, false)) {
                    throw err;
                }
                return EMPTY;
            })
        );
}
