import {
    BehaviorSubject,
    combineLatest,
    defer,
    Observable,
    of,
    ReplaySubject,
    Subject,
    throwError
} from 'rxjs';
import { BridgesMap } from './bridge';
import { AppContact } from './contact';
import { Phonebook } from './phonebook';
import type { ChatMessage } from '../chat/chat-service/chat-message';
import { MyExtensionInfoEx } from './my-extension-info-ex';
import { ActiveCallsVM } from './active-calls/active-calls';
import { TimeSync } from './time-sync';
import { ActiveCallHandler } from './active-calls/active-call-handler';
import { myPhoneTimeToMilliseconds } from '@webclient/myphone/time-util';
import { QueuesMap } from './queue';
import {
    AnonymousSessionClosed,
    ChatMonitoringEvent,
    ChatTyping,
    Conferences,
    Contact,
    DateTime,
    DnType,
    GroupId,
    LocalConnection,
    LoginInfo,
    MyWebRTCEndpoint,
    NotificationChatFileProgress,
    NotificationChatMessageStatus,
    NotificationChatTransferred,
    NotificationConversationRemoved,
    Parkings,
    PartyWithUnreadMessages,
    RequestExactMatch,
    RequestLookupContact,
    RequestServerTime,
    ResponseAvailableProviders,
    ResponseConversationInfo,
    ResponseExtensionsChanged,
    ResponseSystemParameters
} from '@myphone';
import { LoginData } from '../../generated/fetch';
import { appendPersonalContactInformation } from '../shared/utils.service';
import {
    Logger,
    MY_PHONE_LOG,
    REQ_FULFILLED,
    REQ_PENDING,
    RequestLog
} from './logger';

import { map, scan, share, switchMap } from 'rxjs/operators';
import { ContactMatcher } from './contact-matcher';
import { LocalStorage } from 'ngx-webstorage';
import { LocalStorageKeys } from '../settings/local-storage-keys';
import { BranchCacheManager } from '@webclient/myphone/branch-cache-manager';
import { getMyPhoneHeaders, MyphoneHeaders } from '@webclient/myphone/myphone-header.func';
import { IDataTransport } from '@webclient/myphone/i-data-transport';
import { GenericMessageType } from '@webclient/shared/myphone-types';
import { localBridgeId } from '@webclient/myphone/app-contact-type';
import { publishRef } from '@webclient/rx-share-utils';
import { IsNgswEnabled } from '@webclient/notifications/extended-sw-push.service';
import { Capabilities, getCapabilities } from '@webclient/myphone/capabilities';
import { FullUpdate } from '@webclient/myphone/full-update';
import { ImmediateReconnectError } from '@webclient/myphone/immediate-reconnect-error';

type Transport = (request: Uint8Array) => Observable<ArrayBuffer>;

export class MyPhoneSession {
    readonly caps: Capabilities;
    @LocalStorage(LocalStorageKeys.Version)
    version: string;

    public readonly fullUpdate = new FullUpdate();
    // Check if this session is active now
    public isActive = true;
    public isActive$ = new BehaviorSubject(true);
    private isCachingActiveSubj = new BehaviorSubject(true);
    public isCachingActive$: Observable<boolean>;
    public readonly errorDetected$ = new Subject<never>();
    private resetAbandonedQueueCallsUserSaw$ = new BehaviorSubject<boolean>(true);
    public newAbandonedQueueCallsAmount$: Observable<number>;
    public readonly timeSync = new TimeSync();

    // My own extension data
    public readonly myInfo: MyExtensionInfoEx = new MyExtensionInfoEx();
    public readonly myInfo$: ReplaySubject<MyExtensionInfoEx> = new ReplaySubject<MyExtensionInfoEx>(1);
    public readonly connectionsDeleted$: ReplaySubject<LocalConnection[]> = new ReplaySubject<LocalConnection[]>(1);
    public readonly systemParameters: ResponseSystemParameters = new ResponseSystemParameters();
    public readonly systemParameters$: ReplaySubject<ResponseSystemParameters> = new ReplaySubject<ResponseSystemParameters>(1);
    // Phonebook
    public readonly phonebook: Phonebook = new Phonebook();
    // Local + remote bridges
    public bridgesMap: BridgesMap = {};
    public readonly queues: QueuesMap = {};
    public readonly queues$: ReplaySubject<QueuesMap> = new ReplaySubject<QueuesMap>(1);
    public readonly queuesIdentity$: ReplaySubject<QueuesMap> = new ReplaySubject<QueuesMap>(1);
    // Local extensions
    public readonly bridgesMap$ = new ReplaySubject<BridgesMap>(1);
    public readonly contactMatcher: ContactMatcher;
    public readonly chatIsAllowed$: Observable<boolean>;

    get contactDb() {
        return this.contactMatcher.contactDb;
    }

    public unreadChatCount$: Subject<PartyWithUnreadMessages[]>;
    public newChatMessages$: Subject<ChatMessage[]>;
    public chatMessageState$: Subject<NotificationChatMessageStatus>;
    public availableProviders$: ReplaySubject<ResponseAvailableProviders>;
    public anonymousSessionClosed: Subject<AnonymousSessionClosed>;
    public userTyping$: Subject<ChatTyping>;
    public readonly quickMeetingActive$ = new BehaviorSubject<boolean>(false);
    public chatMonitoringEvent$: Subject<ChatMonitoringEvent>;
    public conversationRemoved$: Subject<NotificationConversationRemoved>;
    public conversationInfo$: Subject<ResponseConversationInfo>;
    public chatTransferred$: Subject<NotificationChatTransferred>;
    public messageDownloadProgress$: Subject<NotificationChatFileProgress>;
    public contactDeleted$: Subject<Contact>;
    public contactUpdated$: Subject<Contact>;
    public responseExtensionChanged$: Subject<ResponseExtensionsChanged>;
    private readonly transport: Transport;
    public readonly cache: BranchCacheManager;
    public readonly activeCalls: ActiveCallsVM;
    public readonly webRTCEndpoint = new MyWebRTCEndpoint({ isWebRTCEnpointRegistered: false });
    public readonly webRTCEndpoint$ = new ReplaySubject<MyWebRTCEndpoint>(1);

    public readonly parkings: Parkings = new Parkings();
    public readonly parkings$: ReplaySubject<Parkings> = new ReplaySubject<Parkings>(1);

    public readonly conferences: Conferences = new Conferences();
    public readonly conferences$: ReplaySubject<Conferences> = new ReplaySubject<Conferences>(1);

    public conferenceGateway = '';
    public specialMenuNumber = '';
    public readonly activeCallHandler: ActiveCallHandler;

    public readonly canEditCompanyPhonebook$: Observable<boolean>;
    public readonly isCrmCreationAllowed$: Observable<boolean>;
    public readonly isMcmMode$: Observable<boolean>;
    public readonly isReceptionist$: Observable<boolean>;
    private readonly headers: MyphoneHeaders;
    private index = 0;

    constructor(public sessionParam: LoginData, private logger: Logger, private dataTransport: IDataTransport, public readonly domainUrl: string) {
        this.caps = getCapabilities(sessionParam.version);
        this.cache = new BranchCacheManager();
        this.contactMatcher = new ContactMatcher(this.systemParameters$);
        this.headers = getMyPhoneHeaders(sessionParam.sessionKey);

        this.onWebRTCChanged();
        this.onBridgesChanged();
        this.checkTheVersion();

        // if (self.fetch && !this.connector) {
        // }
        // else if (this.connector){
        //     this.transport = (request: Uint8Array) => {
        //         // @ts-ignore
        //         return this.connector.postMessage(request);
        //     };
        // }

        this.messageDownloadProgress$ = new Subject<NotificationChatFileProgress>();
        this.newChatMessages$ = new Subject<ChatMessage[]>();
        this.chatMessageState$ = new Subject<NotificationChatMessageStatus>();
        this.userTyping$ = new Subject<ChatTyping>();
        this.chatMonitoringEvent$ = new Subject<ChatMonitoringEvent>();
        this.unreadChatCount$ = new Subject<PartyWithUnreadMessages[]>();
        this.conversationInfo$ = new Subject<ResponseConversationInfo>();
        this.conversationRemoved$ = new Subject<NotificationConversationRemoved>();
        this.anonymousSessionClosed = new Subject<AnonymousSessionClosed>();
        this.chatTransferred$ = new Subject<NotificationChatTransferred>();
        this.availableProviders$ = new ReplaySubject<ResponseAvailableProviders>(1);
        this.contactDeleted$ = new Subject<Contact>();
        this.contactUpdated$ = new Subject<Contact>();
        this.responseExtensionChanged$ = new Subject<ResponseExtensionsChanged>();
        this.activeCallHandler = new ActiveCallHandler(this.timeSync, (num, defaultName, dnType, dn) => this.contactSource(num, defaultName, dnType, dn));
        this.activeCalls = new ActiveCallsVM(this.activeCallHandler);

        // Init newAbandonedQueueCallsAmount$ for badge in left menu
        // TODO: move to some 'wrapper' service
        this.newAbandonedQueueCallsAmount$ = this.resetAbandonedQueueCallsUserSaw$.pipe(
            switchMap(() => this.queuesIdentity$.pipe(
                // Accumulate for each queue the amount of abandoned calls seen (initial abandoned calls) and the total amount of calls(current abandoned calls)
                scan((callsPerQueue, queuesMap) => {
                    const queues = Object.values(queuesMap);

                    // filter out the calls for Queues that do not exist anymore, add missing and update existing
                    if (callsPerQueue) {
                        return queues.map((queue) => {
                            const queueFound = callsPerQueue.find(q => q.queueId === queue.id);
                            const totalQueueAbandonedCalls = Number(queue.stat ? queue.stat.TotalCallsAbandoned : 0);

                            return queueFound
                                ? { // update existing
                                    queueId: queueFound.queueId,
                                    abandonedCallsSeen: Math.min(queueFound.abandonedCallsSeen, totalQueueAbandonedCalls),
                                    abandonedCallsTotal: totalQueueAbandonedCalls
                                } : { // the user is added in a new queue, the abandoned calls should be seen, so we explicitly set the calls seen to 0
                                    queueId: queue.id,
                                    abandonedCallsSeen: 0,
                                    abandonedCallsTotal: totalQueueAbandonedCalls
                                };
                        });
                    }
                    // initial assignment (take the call counter for each queue as is)
                    else {
                        return queues.map(x => {
                            const totalQueueAbandonedCalls = Number(x.stat ? x.stat.TotalCallsAbandoned : 0);
                            return {
                                queueId: x.id,
                                abandonedCallsSeen: totalQueueAbandonedCalls,
                                abandonedCallsTotal: totalQueueAbandonedCalls
                            };
                        });
                    }
                }, undefined as { queueId: number; abandonedCallsSeen: number, abandonedCallsTotal: number }[] | undefined),
            )),
            // Subtract from all the abandoned queue calls, the abandoned queue calls that have been seen
            map(callsPerQueue => {
                const abandonedQueueCallsUserSaw = callsPerQueue?.reduce((totalCalls, queueCalls) => totalCalls + queueCalls.abandonedCallsSeen, 0) ?? 0;
                const abandonedQueueCallsTotal = callsPerQueue?.reduce((totalCalls, queueCalls) => totalCalls + queueCalls.abandonedCallsTotal, 0) ?? 0;
                // should not have negative number but have a safeguard just in case
                return Math.max(abandonedQueueCallsTotal - abandonedQueueCallsUserSaw, 0);
            }),
            publishRef()
        );

        this.renewAmountOfAbandonedQueueCallsThatUserSaw();
        //

        this.canEditCompanyPhonebook$ = this.myInfo.MyGroups$.pipe(map(myGroups => myGroups.Items.some(item => item.AllowToManageCompanyBook)));
        this.isReceptionist$ = this.myInfo.MyGroups$.pipe(map(myGroups => myGroups.Items.some(item => item.RoleName === 'receptionists')));
        const hasHotelFeatures = this.licenseHasHotelFeatures();
        const groups$ = this.bridgesMap$.pipe(switchMap(bridges => combineLatest(Object.values(bridges).map(bridge => bridge.groupsMap$))));
        combineLatest([this.myInfo.MyGroups$, groups$]).subscribe(([groupIds, groupsMap]) => {
            groupsMap.forEach(groupMap => {
                Object.values(groupMap).forEach(group => {
                    // Only for local bridges
                    if (group.bridge.id !== localBridgeId) {
                        return;
                    }
                    // Associate group id with the group
                    const thisGroupId = groupIds.Items.find(item => '' + item.Id === group.id);
                    group.groupId = new GroupId({ ...thisGroupId, AssignClearOperations: hasHotelFeatures && thisGroupId?.AssignClearOperations });
                });
            });
        });

        this.chatIsAllowed$ = this.systemParameters$.pipe(
            map(params => params.ChatIsEnabled),
            // distinctUntilChanged(),
            share({ connector: () => new ReplaySubject<boolean>(1) })
        );

        this.isCrmCreationAllowed$ = this.systemParameters$.pipe(
            map((systemParams) => systemParams.CrmConfigured && systemParams.CrmAddContactAllowed),
            share({ connector: () => new ReplaySubject<boolean>(1) })
        );

        this.isMcmMode$ = this.systemParameters$.pipe(
            map(params => params.MultiCompanyMode),
            share({ connector: () => new ReplaySubject<boolean>(1) })
        );

        this.isCachingActive$ = this.isCachingActiveSubj.asObservable();
    }

    public isPro() {
        return this.sessionParam.licenseType === 4;
    }

    public licenseExpirationDate() {
        return this.sessionParam?.licenseExpiredAt ? new Date(this.sessionParam.licenseExpiredAt) : null;
    }

    public licenseProduct() {
        return this.sessionParam.licenseProduct;
    }

    public licenseHasHotelFeatures() {
        const params = this.sessionParam;
        return (!!params.licenseProduct) && params.licenseProduct !== '3CXPSPBX';
    }

    public renewAmountOfAbandonedQueueCallsThatUserSaw() {
        this.resetAbandonedQueueCallsUserSaw$.next(true);
    }

    public activateCaching() {
        this.isCachingActiveSubj.next(true);
    }

    public deactivateCaching() {
        this.isCachingActiveSubj.next(false);
    }

    public protobufToClientTime(dt: DateTime) {
        return this.timeSync.millisecondsToClientTime(myPhoneTimeToMilliseconds(dt));
    }

    public contactSource(num: string, defaultName: string, dnType: DnType, dn: string) {
        if (dnType === DnType.ExternalLine) {
            // const bridge = this.bridgesMap[dn];
            // if (bridge !== undefined)
            //     num = bridge.prefix + num;
        }
        else if (dnType === DnType.SpecialMenu) {
            if (dn !== num) {
                return `[Voice Mail] - ${this.lookupContactName(num, defaultName, dnType, dn)}`;
            }
            else {
                return 'Special Menu';
            }
        }

        return this.lookupContactName(num, defaultName, dnType, dn);
    }

    private checkTheVersion() {
        if (!this.sessionParam.version) {
            return;
        }
        if (this.version !== this.sessionParam.version) {
            this.version = this.sessionParam.version;
            if (!IsNgswEnabled()) {
                location.reload();
            }
        }
    }

    private lookupContactName(num: string, defaultName: string, dnType: DnType, dn: string) {
        const contact = this.contactMatcher.findContactByDn(num, dnType, dn);
        if (defaultName) {
            return appendPersonalContactInformation(defaultName, contact, this.systemParameters.IsLastFirst);
        }
        if (contact && contact.firstNameLastName) {
            return this.systemParameters.IsLastFirst ? contact.lastNameFirstName : contact.firstNameLastName;
        }
        else {
            return defaultName;
        }
    }

    public onWebRTCChanged() {
        this.webRTCEndpoint$.next(this.webRTCEndpoint);
    }

    public onBridgesChanged() {
        this.bridgesMap$.next(this.bridgesMap);
    }

    public onQueuesChanged() {
        this.queues$.next(this.queues);
    }

    public onQueuesIdentityChanged() {
        this.queuesIdentity$.next(this.queues);
    }

    public deactivate() {
        this.isActive = false;
        this.isActive$.next(this.isActive);
    }

    public get<T extends GenericMessageType>(request: GenericMessageType): Observable<T> {
        return defer(() => {
            if (!this.isActive) {
                return throwError(() => new Error('_i18n.SessionExpired'));
            }
            if (request instanceof RequestServerTime) {
                this.timeSync.requestServerTimeSent = Date.now();
            }
            const index = this.index++;
            const log: RequestLog = {
                loggingLevel: MY_PHONE_LOG,
                reqIndex: index,
                requestState: REQ_PENDING
            };
            if (this.logger.logEnabled) {
                this.logger.showRequest({
                    ...log,
                    typeName: request.typeName,
                    optionalParams: [request]
                });
            }
            return this.joinCache<T>(request).pipe(switchMap((response) => {
                if (this.logger.logEnabled) {
                    this.logger.showRequest({
                        ...log,
                        requestState: REQ_FULFILLED,
                        typeName: request.typeName,
                        optionalParams: [response]
                    });
                }
                // eslint-disable-next-line @typescript-eslint/no-deprecated
                if (response instanceof LoginInfo && !response.IsAuthenticated) {
                    // This session was discareded server side
                    this.deactivate();
                    this.errorDetected$.error(new ImmediateReconnectError());
                    return throwError(() => new Error('_i18n.SessionExpired'));
                }
                else {
                    return of(response);
                }
            }));
        });
    }

    private joinCache<T extends GenericMessageType>(request: GenericMessageType): Observable<T> {
        const lazyExRequest = () => this.dataTransport.transport<T>(request, this.headers, this.domainUrl);
        if (!this.isCachingActiveSubj.value) {
            return lazyExRequest();
        }

        if (request instanceof RequestLookupContact) {
            return this.cache.cacheRequest('RequestLookupContact', request, lazyExRequest);
        }
        else if (request instanceof RequestExactMatch) {
            return this.cache.cacheRequest('RequestLookupContact', request, lazyExRequest);
        }
        // else if (request instanceof RequestUpdateContact){
        //     this.cache.deleteBrunch("RequestLookupContact")
        // }
        return lazyExRequest();
    }

    public fetchPost(link: string, content: any): Observable<any> {
        return this.dataTransport.fetchPost(link, content, this.headers, this.domainUrl);
    }

    public createMergedContact(contact: Contact | undefined): AppContact {
        return this.contactMatcher.createMergedContact(contact);
    }
}
