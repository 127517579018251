import { AppContact, defaultContact } from '../myphone/contact';

import { DnType, LocalConnectionState } from '@myphone';
import { dummyMediaDescription, MediaDescription } from './media-description';
import { PushAction } from '@webclient/phone/phone.interface';

export class MyCall {
    constructor(public readonly myCallId: number, public isWebRtcBrowserAgent: boolean) {
    }

    public isPushCall = false;
    public media : Readonly<MediaDescription> = dummyMediaDescription;
    public isHold = false;

    public isTrying: boolean;
    public isHidden: boolean;
    public isReplaced: boolean;
    public SIPDialogID: string[];
    public DeviceID: string;

    // Other party caller id
    public phone: string;
    public tapiCallId?: string;
    public integrationUrl?: string;
    // Other party display name (result)
    public displayName: string;
    // From LocalConnection result
    public otherPartyDisplayNameLC: string;
    public lcCrmUrl: string; // separate variable just to keep it until contact append then set it to contact
    // Other party contact
    public contact: AppContact = defaultContact;

    // Connection call id
    public callId: number;
    // Connection leg id
    public legId: number;
    // Connection id
    public localConnectionId: number;
    // WebRTC id (associated with related webRTC call if it exists)
    public webRTCId?: number;
    // every time we're doing pickUp/drop we are setting id of replacing connection, to stop working with it in new calls, (we are going to deprecate hidden calls)
    public replacingConnectionId?: number;

    public fromPushWakeUpAction?: PushAction; // to perform action on push after emit
    // Connection state
    public state: LocalConnectionState = LocalConnectionState.UnknownState;

    public deletedConnections: number[] = [];

    public startedAt: number;
    public isQueueCall: boolean;
    public isHoldEnabled: boolean;
    public isVideoEnabled: boolean;
    public isDivertToVmAllowed: boolean;
    public isMuteEnabled: boolean;
    public isAnswerEnabled: boolean;
    public isAutoAnswered: boolean;
    public isConnCapabilitiesTransfer: boolean;
    public isTransferEnabled: boolean;
    public isRecordingEnabled: boolean;
    public isAttTransferEnabled: boolean;
    public joinToConnectionId: number|undefined;
    public isConferenceEnabled: boolean;
    public isConferenceCall: boolean;
    public deviceDirectControl: boolean;
    public isRecording: boolean;
    public isRecordingPaused: boolean;
    public isIncoming: boolean;
    public isUrlAutoOpened: boolean;
    public isPickedUp: boolean;

    // Flags we gonna use to prevent race conditions with webrtc connecting state
    /** flag indicates when webRtc request is processing */
    public isSendingWebrtcRequest: boolean;
    /** flag indicates when webRtc and LocalConnection are matched and attached to the Call */
    // public isWebrtcLcConnectionCompared: boolean;

    public prependNameToCID: boolean;

    public notification?: Notification;

    public otherPartyCompanyContactRefs: string;
    public otherPartyDn: string;
    public otherPartyDnType: DnType;

    get idCallLegInfo() {
        return `{ id: ${this.myCallId}, callId: ${this.callId}, legId: ${this.legId} }`;
    }
}
