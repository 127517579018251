import { Param } from '@xapi';

export function odataParamEncoder(param: Param) {
    // Copy from defaultEncodeParam
    let value = param.value;

    if (param.dataType === 'string' && typeof param.value === 'string') {
        value = `'${param.value.replaceAll("'", "''")}'`;
    }
    else
        if (param.dataFormat === 'date-time' && param.value instanceof Date) {
            value = (param.value).toISOString();
        }

    return encodeURIComponent(String(value));
}

export function escapeODataSearch(search: string|null|undefined) {
    if (!search) {
        return undefined;
    }
    return `"${search.replaceAll('"', '\\"')}"`;
}

export function escapeODATAString(str: string, toLowerCase = false) {
    let result = str.replace(/'/g, "''");

    if (toLowerCase) {
        result = result.toLowerCase();
    }

    return result;
}
