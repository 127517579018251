import { Injectable } from '@angular/core';
import { String } from './string.utils';
import { DateTime, ResponseSystemParameters, SortContactsBy } from '@myphone';
import type { AppContact } from '../myphone/contact';
import type { LocalConnectionEx } from '../myphone/local-connection-ex';
import type { ContactSource } from '../myphone/active-calls/active-call-info';
import type { ModalService } from '../modal/app-modal.service';
import { isMailFine } from '@3cx/is-mail-fine';
import { combineLatest, Observable, switchMap } from 'rxjs';
import type { MyPhoneSession } from '@webclient/myphone/myphone-session';
import { distinctUntilChanged, map } from 'rxjs/operators';
import type { LocalStorageService } from 'ngx-webstorage';
import { observe } from '@webclient/rx-utils';
import { LocalStorageKeys } from '@webclient/settings/local-storage-keys';
import { AppContactType } from '@webclient/myphone/app-contact-type';

export enum ProviderImageType {
    Icon = 0,
    Logo = 1,
}

export function protoBufToJsDateTime(dt: DateTime): Date | undefined {
    if (dt == null) {
        return undefined;
    }
    return new Date(Date.UTC(dt.Year, dt.Month - 1, dt.Day, dt.Hour, dt.Minute, dt.Second));
}

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
    public static getUserScopedLocalStorageKey(extNumber: string, localStorageKey: string) {
        return `${extNumber}__${localStorageKey}`;
    }

    public static isValidPhoneNumber(phoneNumber: string | undefined): phoneNumber is string {
        return Boolean(
            phoneNumber
            && /^\+?([()*\-.#\s0-9]*)$/.test(phoneNumber)
            && phoneNumber.replaceAll(/\D/g, '').length >= 4
        );
    }

    public static substrEmailToName = (email: string) => email.substring(0, email.lastIndexOf('@'));
}

export function stripSpecialCharaceters(phone: string | undefined): string | undefined {
    if (String.isNullOrEmpty(phone)) {
        return phone;
    }
    const NotAllowedCharactersRegex = /[^%+*#0-9a-zA-Z]/g;
    phone = phone.trim().replace(NotAllowedCharactersRegex, '');
    // var activeProfile = Biz.Instance.GetActiveProfile();//todo check if cti
    // if (!Biz.Instance.IsProfilesUsingCTI || activeProfile == null)
    // return number;

    phone = phone.replace('%2b', '+');
    phone = phone.replace('%2B', '+');
    // if (activeProfile.UseInternationalDialingCode)// todo check if using International Dialing
    // number = number.Replace("+", activeProfile.InternationalDialingCode);
    return phone;
}

export function isValidEmail(input: string): boolean {
    return isMailFine(input, true, true);
}

export function providerImageLink(ident: string, sessionId: string, type: number) {
    return `/MyPhone/providericon?encodedProvider=${ident}&sessionId=${sessionId}&type=${type}`;
}
export function providerImageFullLink(providerType: string| undefined, session: MyPhoneSession, imageType: ProviderImageType = ProviderImageType.Icon) {
    if (providerType) {
        return new URL(providerImageLink(providerType, session.sessionParam.sessionKey, imageType), session.domainUrl).href;
    }
    else {
        return undefined;
    }
}

export const conversationsInWindow = () => Math.round((window.innerHeight * 2.5) / 60);

export function appendPersonalContactInformation(displayName: string, contact: AppContact | undefined, isLastFirst: boolean) {
    if (!contact || !(contact.type === AppContactType.PersonalPhonebook)) {
        return displayName;
    }

    const append = (isLastFirst ? contact.lastNameFirstName : contact.firstNameLastName).trim();
    if (!append) {
        return displayName;
    }

    return `${displayName}:${append}`;
}

export function updateConnectionPresentationNames(_contactSource: ContactSource, lc: LocalConnectionEx) {
    {
        let o = _contactSource(lc.OtherPartyCallerId, lc.OtherPartyDisplayName, lc.OtherPartyType, lc.OtherPartyDn);
        if (lc.OtherPartyCallerId && !(lc.OtherPartyCallerId === o)) {
            o += ' ' + lc.OtherPartyCallerId;
        }
        lc.OtherPartyPresentationString = o;
    }

    {
        let o = _contactSource(lc.OwnerCallerId, lc.OwnerDisplayName, lc.OwnerType, lc.OwnerDn);
        if (lc.OwnerCallerId && !(lc.OwnerCallerId === o)) {
            o += ' ' + lc.OwnerCallerId;
        }
        lc.OwnerPresentationString = o;
    }
}

export function openNewWindowWhenPopupIsBlocked(url: string): boolean {
    const newWin = window.open(url);

    return !!newWin && !(newWin.closed || typeof newWin.closed === 'undefined');
}

export function openUrlWithMessageBoxIfPopupBlocked(url: string, modalService: ModalService, message: string, header: string, messageTranslateParams?: Record<string, unknown>) {
    const isOpened = openNewWindowWhenPopupIsBlocked(url);
    if (!isOpened) {
        modalService.confirmation(message, header, messageTranslateParams).subscribe(() => {
            window.open(url);
        });
    }
}

export function mySortOrder(sortByExtensionNumber: boolean, params: ResponseSystemParameters) {
    return sortByExtensionNumber ? SortContactsBy.Number : params.IsLastFirst ? SortContactsBy.LastName : SortContactsBy.FirstName;
}

export const sortContactsBy$ = (session: Observable<MyPhoneSession>) =>
    session.pipe(
        switchMap(mySession => mySession.systemParameters$),
        map(sysParameters => mySortOrder(false, sysParameters)),
        distinctUntilChanged()
    );

export function sortMixedListBy(storage: LocalStorageService) {
    const sortByExtensionNumber$ = observe(storage, LocalStorageKeys.SortByExtensionNumber, false);

    return (session: Observable<MyPhoneSession>) =>
        session.pipe(
            switchMap(mySession => combineLatest([mySession.systemParameters$, sortByExtensionNumber$])),
            map(([sysParameters, sortByExtensionNumber]) => mySortOrder(sortByExtensionNumber, sysParameters)),
            distinctUntilChanged()
        );
}

export function clickLink(link: string) {
    const href = document.createElement('a');
    href.href = link;
    href.target = '_blank';
    document.body.appendChild(href);
    href.style.display = 'none';
    href.click();
    href.remove();
}

export function downloadURL(link: string, fileName?: string) {
    const href = document.createElement('a');
    href.href = link;
    href.download = fileName ?? '';
    document.body.appendChild(href);
    href.style.display = 'none';
    href.click();
    href.remove();
}

export function exportContent(content: string, filename: string, type: string = 'text/csv;charset=utf-8;') {
    const blob = new Blob([content], { type });
    const url = window.URL.createObjectURL(blob);
    downloadURL(url, filename);
    window.URL.revokeObjectURL(url);
}

export function fileReader(files:FileList, result: (e: any, fileInfo: File) => void) {
    const fileInfo = files.item(0);
    const reader = new FileReader();
    if (fileInfo) {
        reader.readAsText(fileInfo, 'UTF-8');
        reader.onload = (e) => {
            result(e, fileInfo);
        };
        reader.onerror = (e) => {
            console.error(e);
        };
    }
}

export function readFile(fileInfo: File, onLoad: (content: string | ArrayBuffer | null) => void, onError?: (event: ProgressEvent<FileReader>) => void) {
    const reader = new FileReader();

    reader.readAsText(fileInfo, 'UTF-8');
    reader.onload = e => onLoad(e.target?.result ?? null);
    reader.onerror = e => onError?.(e);
}
