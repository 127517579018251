import { Component } from '@angular/core';

@Component({
    standalone: true,
    selector: 'app-spinner-regular-icon,[app-spinner-regular-icon]',
    templateUrl: '../../../../img/icons/font-awesome/regular/spinner.svg'
})

export class SpinnerRegularIconComponent {
}
