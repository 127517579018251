/**
 * Angular bootstrapping
 */

/**
 *  DayJs
 *  Extend Plugins
 */
import './app/dayjs/plugins';
import { provideMyPhone } from '@webclient/myphone/myphone.module';
import { provideTranslateService, TranslateLoader } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, importProvidersFrom } from '@angular/core';
import { MyErrorHandler } from './app/my-error-handler';
import { provideNgxWebstorage, withLocalStorage, withNgxWebstorageConfig } from 'ngx-webstorage';
import { AuthTokenInterceptor } from '@webclient/auth/auth-token-interceptor';
import { XSRFInterceptor } from './app/xsrf-interceptor';
import { Configuration } from '@api';
import { Configuration as XapiConfiguration, Param } from '@xapi';
import { odataParamEncoder } from '@webclient/shared/odata-key';
import { bootstrapApplication } from '@angular/platform-browser';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { provideAnimations, provideNoopAnimations } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { provideNgIdle } from '@ng-idle/core';
import { provideRouter, withHashLocation } from '@angular/router';
import { ROUTES } from './app/app.routes';
import { provideServiceWorker } from '@angular/service-worker';
import { ExtendedServiceWorker } from '@webclient/notifications/extended-sw-push.service';
import { AppComponent } from './app/app.component';
import { isSelenium } from '@webclient/environment';
import { BundleTranslateLoader } from '@webclient/bundle-translate-loader';
import { setTheme } from 'ngx-bootstrap/utils';

const nav = <any>navigator;
if (nav.mediaSession && nav.mediaSession.setActionHandler) {
    nav.mediaSession.setActionHandler('play', () => { /* Code excerpted. */
    });
    nav.mediaSession.setActionHandler('pause', () => { /* Code excerpted. */
    });
}

export function animationDriverFactory() {
    return isSelenium ? provideNoopAnimations() : provideAnimations();
}

// Explicitly set the bootstrap theme style
setTheme('bs5');

/**
 * Bootstrap our Angular app with a top level NgModule
 */
bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            NgxLoadingModule.forRoot({
                animationType: ngxLoadingAnimationTypes.pulse,
            }),
            ModalModule.forRoot(),
        ),
        {
            provide: Configuration,
            useValue: new Configuration({
                withCredentials: false
            })
        },
        {
            provide: XapiConfiguration,
            useValue: new XapiConfiguration({
                withCredentials: false,
                encodeParam: (param: Param) => odataParamEncoder(param),
            })
        },
        provideNgIdle(),
        provideServiceWorker(ExtendedServiceWorker, {
            scope: window.location.pathname,
            enabled: 'serviceWorker' in navigator, // environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        provideMyPhone(),
        provideTranslateService({
            loader: {
                provide: TranslateLoader,
                useClass: BundleTranslateLoader,
            }
        }),
        provideNgxWebstorage(withNgxWebstorageConfig({ separator: '.', caseSensitive: false, prefix: 'wc' }), withLocalStorage()),
        {
            provide: ErrorHandler,
            useClass: MyErrorHandler
        },
        animationDriverFactory(),
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthTokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: XSRFInterceptor,
            multi: true
        },
        provideRouter(ROUTES, withHashLocation())
    ]
})
    .catch((err) => console.error(err));
