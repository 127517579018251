import {
    distinctUntilChanged, map, observeOn, switchMap, take
} from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';
import {
    asyncScheduler, from, Observable, of, ReplaySubject, share, Subject
} from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { LocalStorageKeys } from '../../settings/local-storage-keys';
import { HeadsetContact, HeadsetInterface } from './headset-interface';
import { DummyService } from './dummy/dummy.service';
import { HeadsetCallCommand } from './headset-call-command';
import { HeadsetDiagnostics } from './headset-diagnostics';
import * as Bowser from 'bowser';
import checkTree = Bowser.Parser.checkTree;
import { enterZone } from '@webclient/myphone/zone-utils';
import { WebhidHeadsetService } from '@webclient/phone/headsets/webhib-headset.service';
import { observe } from '@webclient/rx-utils';

const browser = Bowser.getParser(window.navigator.userAgent);

export interface HeadsetOption {
    value: string;
    label: string;
    allowedPlatforms?: checkTree[];
    prohibitedPlatforms?: checkTree[];
}

@Injectable({
    providedIn: 'root'
})
export class HeadsetService {
    public readonly impl$: Observable<HeadsetInterface>;
    public headsetEvents$: Observable<HeadsetCallCommand>;
    public readonly diagnostics$: Observable<HeadsetDiagnostics>;

    public AvailableHeadsets : HeadsetOption[];

    private readonly emptyHeadset: HeadsetOption = { value: '', label: '_i18n.HeadSetNone' };
    private readonly allHeadSets: HeadsetOption[] = [
        this.emptyHeadset,
        {
            value: 'yealinkHID', label: 'Yealink'
        },
        {
            value: 'generic', label: '_i18n.UniversalHeadsetDriver'
        },
        {
            value: 'jabraLegacy', label: '_i18n.JabraLegacy'
        },
        {
            value: 'plantronicsLegacy', label: '_i18n.PlantronicsLegacy', prohibitedPlatforms: [{ firefox: '>0' }]
        },
    ];

    private headsetManagers: { [id: string]: HeadsetInterface } = {};
    private readonly incomingCalls = new Set<number>();
    private readonly outgoingCalls = new Set<number>();
    private readonly establishedCalls = new Set<number>();

    constructor(localStorage: LocalStorageService, zone: NgZone) {
        this.AvailableHeadsets = this.allHeadSets.filter(head => this.isHeadsetAvailable(head.value));

        const selectedHeadset = observe<string>(localStorage, LocalStorageKeys.SelectedHeadset, '').pipe(
            map(selectedHeadSet => {
                const normalizedName = this.normalizeHeadsetName(selectedHeadSet);
                return this.isHeadsetAvailable(normalizedName) ? normalizedName : this.emptyHeadset.value;
            }),
            distinctUntilChanged()
        );

        this.impl$ = selectedHeadset.pipe(
            switchMap((enabled: string) => {
                if (this.headsetManagers[enabled]) {
                    return of(this.headsetManagers[enabled]);
                }

                if (enabled === 'generic') {
                    this.headsetManagers[enabled] = new WebhidHeadsetService();
                    return of(this.headsetManagers[enabled]);
                }
                else if (enabled === 'yealinkHID') {
                    return from(import(/* webpackChunkName: "yealink" */ './yealink/yealink-hid.service'))
                        .pipe(map(module => {
                            this.headsetManagers[enabled] = new module.YealinkHidService();
                            return this.headsetManagers[enabled];
                        }));
                }
                else if (enabled === 'jabraLegacy') {
                    return from(import(/* webpackChunkName: "jabra-new" */ './jabra/jabra-headset-client.service'))
                        .pipe(map(module => {
                            this.headsetManagers[enabled] = new module.JabraHeadsetClientService();
                            return this.headsetManagers[enabled];
                        }));
                }
                else if (enabled === 'plantronicsLegacy') {
                    return from(import(/* webpackChunkName: "plantronics" */ './plantronics/plantronics.service'))
                        .pipe(map(module => {
                            this.headsetManagers[enabled] = new module.PlantronicsService();
                            return this.headsetManagers[enabled];
                        }));
                }
                else {
                    return of(new DummyService());
                }
            }),
            share({
                connector: () => new ReplaySubject<HeadsetInterface>(1),
            })
        );

        this.headsetEvents$ = this.impl$.pipe(switchMap(impl => impl.headsetEvents$),
            observeOn(enterZone(zone, asyncScheduler)),
            share({
                resetOnRefCountZero: true,
                connector: () => new Subject<HeadsetCallCommand>(),
            })
        );

        this.diagnostics$ = this.impl$.pipe(switchMap(impl => impl.diagnostics$),
            observeOn(enterZone(zone, asyncScheduler)),
        );
    }

    // has to be called whenever the headset name is parsed from local storage
    public normalizeHeadsetName(headset: string) {
        // in case the headset value in storage is 'jabra'/'sennheiser'/'plantronics' set it to 'generic'
        return ['jabra', 'plantronics', 'sennheiser'].includes(headset) ? 'generic' : headset;
    }

    public isHeadsetAvailable(value: string): boolean {
        const headset = this.allHeadSets.find(h => h.value.toLocaleLowerCase() === (value || '').toLocaleLowerCase());
        if (headset?.value && !('hid' in navigator)) {
            // All headsets require WebHID
            return false;
        }
        if (headset && headset.allowedPlatforms && headset.allowedPlatforms.length > 0) {
            return !!headset.allowedPlatforms.find(platform => browser.satisfies(platform));
        }
        if (headset && headset.prohibitedPlatforms && headset.prohibitedPlatforms.length > 0) {
            return !headset.prohibitedPlatforms.find(platform => browser.satisfies(platform));
        }
        return Boolean(headset);
    }

    private execAction(action: (impl: HeadsetInterface) => void) {
        this.impl$.pipe(take(1)).subscribe(impl => action(impl));
    }

    public incomingCall(callId: number, contact: HeadsetContact) {
        this.incomingCalls.add(callId);
        this.execAction(impl => impl.incomingCall(callId, contact));
    }

    public outgoingCall(callId: number, contact: HeadsetContact) {
        this.outgoingCalls.add(callId);
        this.execAction(impl => impl.outgoingCall(callId, contact));
    }

    public callAccepted(callId: number) {
        if (this.incomingCalls.has(callId)) {
            this.incomingCalls.delete(callId);
            this.establishedCalls.add(callId);
            this.execAction(impl => impl.incomingCallAccepted(callId));
        }
        else if (this.outgoingCalls.has(callId)) {
            this.outgoingCalls.delete(callId);
            this.establishedCalls.add(callId);
            this.execAction(impl => impl.outgoingCallAccepted(callId));
        }
    }

    public reset() {
        const idsToTerminate = Array.from(this.establishedCalls).concat(
            Array.from(this.incomingCalls),
            Array.from(this.outgoingCalls)
        );
        this.establishedCalls.clear();
        this.incomingCalls.clear();
        this.outgoingCalls.clear();
        this.execAction(impl => {
            idsToTerminate.forEach(id => impl.terminateCall(id));
        });
    }

    public terminateCall(callId: number) {
        this.outgoingCalls.delete(callId);
        this.incomingCalls.delete(callId);
        this.establishedCalls.delete(callId);
        this.execAction(impl => impl.terminateCall(callId));
    }

    public holdCall(callId: number) {
        this.execAction(impl => impl.holdCall(callId));
    }

    public resumeCall(callId: number) {
        this.execAction(impl => impl.resumeCall(callId));
    }

    public mute(callId: number, isMuted: boolean) {
        this.execAction(impl => impl.mute(callId, isMuted));
    }

    public connectDevice() {
        this.execAction(impl => impl.connectDevice());
    }
}
