import { Component } from '@angular/core';

@Component({
    standalone: true,
    selector: 'app-exclamation-triangle-regular-icon,[app-exclamation-triangle-regular-icon]',
    templateUrl: '../../../../img/icons/font-awesome/regular/exclamation-triangle.svg'
})

export class ExclamationTriangleRegularIconComponent {
}
