export const LocalStorageKeys = {
    OpenId: 'OpenId',
    BrandUrl: 'BrandUrl',
    BrandImage: 'BrandImage',
    ShowUnregisteredExtensions: 'ShowUnregisteredExtensions',
    SortByExtensionNumber: 'SortByExtensionNumber',
    ShowPersonalContacts: 'ShowPersonalContacts',
    AsideFolded: 'AsideFolded',
    HiddenGroups: 'HiddenGroups',
    Provision: 'Provision',
    SharedWorker: 'sharedWorker',
    ManagerSelectedQueue: 'ManagerSelectedQueue',
    SelectedPhoneDevice: 'SelectedPhoneDevice',
    Language: 'Language',
    ActiveCallsFilter: 'ActiveCallsFilter',
    LoggerEnabled: 'LoggerEnabled',
    LoggerLevel: 'LoggerLevel',
    GeneralSettings: 'GeneralSettings',
    KeyboardShortcuts: 'KeyboardShortcuts',
    SwitchboardQueueFilter: 'SwitchboardQueueFilter',
    WallboardQueueFilter: 'WallboardQueueFilter',
    ChatBlackList: 'chatBlackList',
    ExternalCalendarTypeId: 'externalCalendarTypeId',
    ChatInfoOpen: 'chatInfoOpen',
    BrowserExtForceProv: 'browserextension',
    SelectedCamera: 'SelectedVideoDevice',
    SelectedMicrophone: 'SelectedAudioDevice',
    SelectedSpeaker: 'SelectedSpeakerDevice',
    SelectedRinger: 'SelectedRingingDevice',
    SilentMode: 'SilentMode',
    SelectedHeadset: 'SelectedHeadset',
    DontShowIcsNotify: 'DontShowIcsNotify',
    AutoAnswer: 'AutoAnswer',
    FocusOnIncomingCall: 'FocusOnIncomingCall',
    BrowserDisclaimerShown: 'BrowserDisclaimerShown',
    ActiveHost: 'activehost',
    PushDisabled: 'pushdisabled',
    Version: 'version',
    Lab: 'lab',
    ShowBlf: 'ShowBlf',
    Provider: 'BestChatProvider',
    SavePeople: 'SavePeople',
    SaveContactsQueryParams: 'SaveContactsQueryParams',
    WebRtcCodecPriority: 'WebRtcCodecPriority',
    AdvanceEditor: 'AdvanceEditor',
    LogoutTimestamp: 'LogoutTimestamp',
    ChatTemplateSelectedLanguage: 'ChatTemplateSelectedLanguage',
    ChatTemplateSelectedGroup: 'ChatTemplateSelectedGroup',
    UsersPageSize: 'UsersPageSize',
    EventLogsPageSize: 'EventLogsPageSize',
    RecordingsPageSize: 'RecordingsPageSize',
    SessionsPageSize: 'SessionsPageSize',
    M365AddUsers: 'M365AddUsers',
    GoogleAddUsers: 'GoogleAddUsers',
    AddPeers: 'AddPeers'
};

export const defaultTeamLink = ['/people'];

export const defaultContactsQueryParams = { };

export const ModalPageSizeKey = LocalStorageKeys.AddPeers;
