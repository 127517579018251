import { Component, OnDestroy, OnInit } from '@angular/core';
import { MyPhoneService } from './myphone/myphone.service';
import { SplashService } from './splash.service';
import { filter, pairwise, take } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import { observe } from '@webclient/rx-utils';
import { LocalStorageKeys } from '@webclient/settings/local-storage-keys';
import { LocalStorageService } from 'ngx-webstorage';
import { Router, RouterOutlet } from '@angular/router';
import { LangService } from '@webclient/lang.service';
import { BrandingService } from '@webclient/branding.service';
import { BannerContainerComponent } from './shared/components/banner-container.component';
import { SnackbarComponent } from '@webclient/shared/snackbar.component';

@Component({
    template: `
        <banner-container></banner-container>
        <router-outlet></router-outlet>
        <snackbar [reconnectTime]="myPhoneService.reconnectTime"
                  (retryNow)="myPhoneService.retryNow$.next ()"
                  [isVisible]="myPhoneService.isReconnecting" ></snackbar>
    `,
    standalone: true,
    imports: [BannerContainerComponent, RouterOutlet, SnackbarComponent]
})
export class MyphoneSessionComponent implements OnDestroy, OnInit {
    private readonly sub = new Subscription();

    constructor(protected myPhoneService: MyPhoneService, private splashService: SplashService,
                private localStorageService: LocalStorageService, private router: Router,
                private lang: LangService, private brandingService: BrandingService) {
    }

    ngOnInit() {
        this.myPhoneService.connect();
        this.sub.add(this.myPhoneService.myPhoneSession.pipe(take(1)).subscribe(() => {
            this.splashService.remove();
        }));

        this.sub.add(combineLatest([this.lang.sessionLanguage$, this.lang.isExtensionLanguageOverwritten$])
            .subscribe(([sessionLanguage, isExtensionLanguageOverwritten]) => {
                const localStorageLang = this.localStorageService.retrieve(LocalStorageKeys.Language);
                if (!isExtensionLanguageOverwritten && !!localStorageLang && localStorageLang !== '') {
                    this.lang.requestSetSystemLanguage(localStorageLang);
                }
                else {
                    this.localStorageService.store(LocalStorageKeys.Language, sessionLanguage);
                }
            }));

        this.sub.add(observe(this.localStorageService, LocalStorageKeys.LogoutTimestamp, -1).pipe(
            pairwise(),
            filter(([, newTimeStamp]) => newTimeStamp > 0)
        ).subscribe(() => {
            this.router.navigate(['/login']);
        }));

        this.sub.add(
            this.brandingService.AvatarStyle$.subscribe((avatarStyle) => {
                this.brandingService.attachAvatarStyleClassToBody(avatarStyle);
            })
        );
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
        this.myPhoneService.disconnect();
    }
}
