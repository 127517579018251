export type MaxFileSizeValidation = 1 | 2 | 5 | 10 | 16 | 50 | 300 | 1024;

export const MB = 1024 * 1024;

export const GB = 1024 * MB;

export const MaxFailoverScriptSizeMB = 1;

export const MaxImportFileSizeMB = 10;

export const MaxAudioFileSizeMB = 10;

export const MaxLogoWeightSizeMB = 2;

export const MaxTemplateFileSizeMB = 1;

export const MaxFirmwareSizeMB = 1024;

export const MaxPhoneLogoSizeMB = 5;

export const DefaultAllowedNginxSizeMB = 300;

export const MaxChatFileSizeBeforeCompressionMB = 50;

export const ProfileImageFileSizeMB = 16;
