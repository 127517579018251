import { jwtDecode } from 'jwt-decode';
import {
    CanDoAnything,
    RoleId, CanLicenseInformation, CanMCMLicenseInformation, CanEventLog
} from '@office/users/user-general/roles';

function decodeTokenPayload(token: string | undefined): any {
    if (!token) {
        return {};
    }

    return jwtDecode(token);
}

export function decodeToken(token: string | undefined) {
    return token ? decodeTokenPayload(token) : undefined;
}

export function getRoles(token: any): string[] {
    const roles = token.role;
    return roles ? (Array.isArray(roles) ? roles : [roles]) : [];
}

export function hasMyGroupRole(roles: string[]) {
    return roles.includes('MyGroup');
}

export function hasAdminRole(roles: string[]) {
    return roles.includes('Admin');
}

export function canManageCustomPrompts(roles: string[]) {
    return roles.includes('Groups.Create') || roles.includes('Groups.ReadWrite') || roles.includes('PhoneSystemAdmin');
}

// Based on roles reported by MyPhone
export const hasLicenseInformation = (role: RoleId) => CanLicenseInformation.some(x => role === x);

export const hasEventLog = (role: RoleId) => CanEventLog.some(x => role === x);
export const hasIntegrations = (role: RoleId) => CanDoAnything.some(x => role === x);
export const hasAdvanced = (role: RoleId) => CanDoAnything.some(x => role === x);
export const hasMCMLicenseInformation = (role: RoleId) => CanMCMLicenseInformation.some(x => role === x);
export const hasFullAccess = (role: RoleId) => CanDoAnything.some(x => role === x);

export interface MyToken {
    readonly id: string;
    // User name (most probably extension)
    readonly userName: string;
    readonly myRole: RoleId;
    // Raw roles
    readonly roles: string[];
    // Overall access to admin section
    readonly adminSection: boolean;
    readonly machineAdmin: boolean;
    readonly globalAdmin: boolean;
    // Admin role
    readonly admin: boolean;
    // outboundRules role
    readonly outboundRules: boolean;
    // Access to users section
    readonly users: boolean;
    // Access to call routing section
    readonly trunks: boolean;
    // Access to reports section
    readonly reports: boolean;
    // Access to audit log section
    readonly audit: boolean;
    // Access groups section
    readonly groups: boolean;
    // Access call handling section
    readonly callHandling: boolean;
    readonly singleCompany: boolean;
    // Access office hours section
    readonly officeHours: boolean;
    // Paid license
    readonly paid: boolean;
    // enterprise license
    readonly enterprise: boolean
    // branding enabled
    readonly branding: boolean
}
