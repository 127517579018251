import { Component, Input } from '@angular/core';
import { ModalButtons } from '@webclient/modal/message-box';
import { DialogComponent } from '@webclient/modal/dialog';
import { ModalDialogComponent } from '../../standalones/modal-dialog/modal-dialog.component';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'app-messagebox',
    template: `
        <app-modal-dialog [header]="header" [buttons]="buttons" [customText]="customText" [customClass]='customClass' (submitted)="onSubmit()" (customClicked)="onCustom()" [scrollable]="true">
            <p data-qa="modal-text" [innerHTML]="message | translate:messageTranslateParams"></p>
        </app-modal-dialog>
    `,
    standalone: true,
    imports: [ModalDialogComponent, TranslatePipe]
})
export class MessageBoxComponent extends DialogComponent {
    @Input() header = '';
    @Input() message = '';
    @Input() customText = '';
    @Input() customClass = 'btn-border';
    @Input() override buttons: ModalButtons = ModalButtons.Ok;
    @Input() messageTranslateParams?: unknown;
}
