import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { isValidEmail, UtilsService } from '@webclient/shared/utils.service';
import { PbxConcealedPassword, PbxLiveChatCommunication } from '@xapi';
import { isIP } from 'is-ip';
import isValidHostnameFunc from 'is-valid-hostname';
import { parse } from 'ipaddr.js';
import { DefaultAllowedNginxSizeMB, MaxAudioFileSizeMB, MaxFileSizeValidation, MB } from '../file-limits';
import { CustomError } from '@webclient/custom-error';
import { fileSizeMbLimitError } from '@webclient/file-size-utils';

export const conditionalValidator = (condition: () => boolean, validator: ValidatorFn): ValidatorFn =>
    control => (condition() ? validator(control) : null);

export const emailValidator: ValidatorFn = control =>
    (control.value && !isValidEmail(control.value) ? { email: true } : null);

export const emailListValidator: ValidatorFn = control =>
    (control.value && control.value.split(',').some((part: string) => part.trim() && !isValidEmail(part.trim())) ? { emailList: true } : null);

export const phoneValidator: ValidatorFn = control =>
    (control.value && !UtilsService.isValidPhoneNumber(control.value) ? { mobileNumberIsInvalid: true } : null);

export const hasStarCode = (value: string) => /\*/.test(value);
export const notStarCode: ValidatorFn = control => (hasStarCode(control.value) ? { starCodeError: true } : null);

export const invalidDid = (value: string, mcm?: boolean | null) => value.search(/^[^\s"%]*$/) === -1 || (mcm && /\*/.test(value));

export const didValidator: ValidatorFn = control => (control.value && invalidDid(control.value) ? { didIsInvalid: true } : null);

export const invalidDidName = (value: string) => value.includes('"') || value.includes('%');

// Disallow quotes like in DID
export const didNameValidator: ValidatorFn = control => (control.value && invalidDidName(control.value) ? { didIsInvalid: true } : null);

export const authenticationIdValidator: ValidatorFn = control => (control.value && control.value.search(/^[^\s"]*$/) === -1 ? { authenticationId: true } : null);

export const endWithStarCode = (value: string) => value.endsWith('*');
export const notEndWithStarCodeValidator: ValidatorFn = control => (endWithStarCode(control.value) ? { endWithStarCode: true } : null);

export const forwardingDNValidator: ValidatorFn = control =>
    (control.value && !/^[a-zA-Z0-9+*._]+$/.test(control.value) ? { invalidDirectNumber: true } : null);

export const myMobileForwardingValidator: ValidatorFn = control => {
    if (!control.value) {
        return { myMobileIsForwardedToAndEmpty: true };
    }
    if (forwardingDNValidator(control)) {
        return { myMobileIsForwardedToAndInvalid: true };
    }
    return null;
};

export const communicationOptionValidator: ValidatorFn = control =>
    (control.value === PbxLiveChatCommunication.VideoPhoneAndChat ? { communicationOptionError: true } : null);

export const click2TalkMaxLength = 20;

// max length 50 should be checked with separate validator to show correct validation messages
export const weakPasswordValidators = [Validators.maxLength(50), Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}$/)];

export function convertToConcealedPasswordValidator(validator: ValidatorFn): ValidatorFn {
    return control => (control.value?.Concealed ? null : validator({ value: control.value?.Value } as AbstractControl));
}

const sbcPasswordInvalidChars = Validators.pattern(/^[^\\*;#"&<>^$%)(@`/:!{}\[\]|+'=\-_ ]*$/);

export const totpValidator = Validators.pattern(/^\d{6}$/);
export const sbcPasswordValidators = [Validators.required, ...weakPasswordValidators, sbcPasswordInvalidChars];

// Server requires MAC to be uppercased where appropriate
export const macServerRegex = /^[0-9A-F]{12}$/i;

export const macValidator = Validators.pattern(/^[0-9a-f]{12}$/i);

export const alphanumValidator : ValidatorFn = (control: AbstractControl) => {
    return control.value && !/^[a-zA-Z0-9]*$/.test(control.value) ? { invalidAlphanumeric: true } : null;
};

export const alphanumPasswordValidator : ValidatorFn = (control: AbstractControl) => {
    const value = (control.value as PbxConcealedPassword | null)?.Value;
    return alphanumValidator({ value } as AbstractControl);
};

export const alphanumUnderscoreValidatorAndDot : ValidatorFn = (control: AbstractControl) => {
    return control.value && !/^[0-9a-zA-Z_\-.]+$/.test(control.value) ? { invalidChars: true } : null;
};

export const alphanumUnderscoreValidator : ValidatorFn = (control: AbstractControl) => {
    return control.value && !/^[0-9a-zA-Z_\-]+$/.test(control.value) ? { invalidChars: true } : null;
};

// eslint-disable-next-line no-control-regex
export const invalidHex = (value: string) => /[\x00-\x1F]+/.test(value);

export const invalidHexValidator : ValidatorFn = (control: AbstractControl) => {
    return control.value && invalidHex(control.value) ? { invalidHexCharacter: true } : null;
};

export const lowerCaseAlphanumValidator = Validators.pattern(/^[a-z0-9]*$/);

export const lowerCaseUnderscoreAlphanumValidator = Validators.pattern(/^[a-z_0-9]*$/);

export const callFlowPhoneValidator = Validators.pattern(/^[a-z0-9*]*$/);

export const integerValidator = Validators.pattern(/^[-]?[0-9]+$/);

export const whitespaceValidator : ValidatorFn = (control: AbstractControl) => {
    return control.value && !control.value.trim() ? { whitespace: true } : null;
};

export const dialCodeValidator : ValidatorFn = (control: AbstractControl) => {
    return control.value && !/^(\\*[0-9*]+)?$/.test(control.value) ? { invalidDialCode: true } : null;
};

export const licenseKeyFormatValidator = Validators.pattern(/^\s*([A-Za-z0-9]){4}-([A-Za-z0-9]){4}-([A-Za-z0-9]){4}-([A-Za-z0-9]){4}\s*$/);

export function extensionNumberValidator(extensionNumberLength?: number | null): ValidatorFn {
    const extensionNumberPattern = extensionNumberLength ? new RegExp(`^[0-9]{${extensionNumberLength}}$`) : new RegExp('^[0-9]*$');
    return (control: AbstractControl): ValidationErrors | null => {
        return !!control.value && !extensionNumberPattern.test(control.value) ? { invalidExtensionNumber: true } : null;
    };
}

/**
 * makes the field required if the predicate function returns true
 */
export function requiredIfValidator(predicate: () => unknown) {
    return (formControl: FormControl) => {
        return formControl.parent && predicate() ? Validators.required(formControl) : null;
    };
}

export const dnValidator: ValidatorFn = (control: AbstractControl) => {
    return control.value?.Number ? null : { required: true };
};

// allow (alphabet, digits, commas, space, quetion mark, exclamation mark, semicolon)

export function isValidBrandingUrl(value: string|null|undefined) {
    try {
        if (!value) {
            return false;
        }
        const t = new URL(value);
        return (t.protocol === 'https:') || (t.protocol === 'http:');
    }
    catch {
        return false;
    }
}

export function urlsValidator(control: AbstractControl) {
    if (!control.value) {
        return null;
    }
    const list: string[] = control.value;
    return list.some(url => !isValidBrandingUrl(url)) ? { url: true } : null;
}

export function listValuesValidator(availableValues: any[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        return !!control.value && availableValues.indexOf(control.value) === -1 ? { listElementNotFound: true } : null;
    };
}

/**
 * This might look strange that we're no using here isValid from ipaddr.js
 * But the issue that isValid allows non-human readable IPs.
 * So we will allow only human-readable but still use ipaddr.js for parsing.
 * ipaddr.js can parse anything that passes this validation
 */
export function isValidIpAddress(value: string) {
    try {
        // isIP gives true for fd00:ffff:0000:0000:1::1.2.3.4, but parse fails, so we need to check both
        const kind = isIP(value) ? parse(value).kind() : undefined;
        // exclude ipv6 dual format by checking for dot in value
        return kind === 'ipv4' || (kind === 'ipv6' && !value.includes('.'));
    }
    catch {
        return false;
    }
}

function isValidHostname(value:string) {
    return isValidIpAddress(value) || isValidHostnameFunc(value);
}

/** ipv4 or ipv6 or domain name without port */
export const isValidHostnameValidator: ValidatorFn = (control: AbstractControl) => {
    if (control.value && !isValidHostname(control.value)) {
        return { invalidIPAddress: true };
    }
    return null;
};

/** ipv4 or ipv6 without port */
export const ipAddressValidator: ValidatorFn = (control: AbstractControl) => {
    if (control.value && !isValidIpAddress(control.value)) {
        return { invalidIPAddress: true };
    }
    return null;
};

/**
 * to be used with a timepicker control which sets the value as null when an invalid time is inserted
 * @param control
 */
export const timePickerValidator: ValidatorFn = (control: AbstractControl) => {
    return !control.value ? {
        invalidTime: true
    } : null;
};

export const ignoreRequiredValidator = (...ignore: string[]) => {
    return (control: AbstractControl<string>) => {
        const value = ignore.reduce(
            (prev, curr) => prev.replaceAll(curr, ''),
            control?.value?.toLowerCase() ?? ''
        );
        return value ? null : { required: true };
    };
};

export const startWithValidator = (...params: string[]) => {
    return (control: AbstractControl<string>) =>
        (control?.value && !params.find(x => control?.value.toLowerCase().startsWith(x.toLowerCase())) ?
            { startWithError: {
                p1: params
            } } : null);
};

export const urlValidator = (ignoreIfOnlyPrefix = false) => (control: AbstractControl<string>) => {
    const { value } = control;

    if (value && (!ignoreIfOnlyPrefix || value.split('//')[1])) {
        try {
            const t = new URL(value);
            return null;
        }
        catch {
            return { invalidUrl: true };
        }
    }
    return null;
};

/**
 * Removes specific error keys from the control leaving existing errors unaffected
 * adapted from https://github.com/angular/angular/issues/21564#issuecomment-480569715
 * @param control
 * @param errorKeys
 */
export function removeErrors(control: AbstractControl, errorKeys: string[]) {
    if (!control || !errorKeys || errorKeys.length === 0) {
        return;
    }
    const remainingErrors = errorKeys.reduce((errors, key) => {
        delete errors[key];
        return errors;
    }, { ...control.errors });
    if (Object.keys(remainingErrors || {}).length === 0) {
        control.setErrors(null);
    }
    else {
        control.setErrors(remainingErrors);
    }
}

/**
 * Adds specific error keys to the control leaving existing errors unaffected
 * adapted from https://github.com/angular/angular/issues/21564#issuecomment-480569715
 * @param control
 * @param errors
 */
export function addErrors(control: AbstractControl, errors: { [key: string]: any }) {
    if (!control || !errors || errors.length === 0) {
        return;
    }
    control.setErrors({ ...control.errors, ...errors });
}

export function toggleError(control: AbstractControl, errors: ValidationErrors, switchOn: boolean) {
    if (switchOn) {
        addErrors(control, errors);
    }
    else {
        removeErrors(control, Object.keys(errors));
    }
}

/** this validator must be used in pair with formWithUniqueFieldsValidator on parent form */
export function uniqueValueValidator(fields?: string[]): ValidatorFn {
    return function uniqueValue(control) {
        const controls = control.parent?.controls as Record<string, AbstractControl> | undefined;

        if (!control.value || !controls) {
            return null;
        }

        const hasDuplicate = Object.keys(controls).some(field => {
            if (fields && fields.length && !fields.includes(field)) {
                return false;
            }
            const other = controls[field];
            return other !== control && other.value === control.value;
        });

        return hasDuplicate ? { unique: true } : null;
    };
}

/* Revalidate not unique fields when their validation should drop by other field value change */
export function formWithUniqueValuesValidator(fields?: string): ValidatorFn {
    return function formWithUniqueValues(control) {
        const controls = (control as FormGroup).controls as Record<string, AbstractControl> | undefined;

        if (!control.value || !controls) {
            return null;
        }
        const controlNames = Object.keys(controls).filter(field => !fields || !fields.length || fields.includes(field));
        const controlValues = controlNames.map(field => controls[field].value);
        const unique = controlValues.filter(value => controlValues.indexOf(value) === controlValues.lastIndexOf(value));

        controlNames.forEach((field) => {
            const control = controls[field];

            if (control.errors?.unique && unique.includes(control.value)) {
                control.updateValueAndValidity({ onlySelf: true });
            }
        });
        return null;
    };
}

export const rangeValidator = (min: number, max: number): ValidatorFn => {
    return (control: AbstractControl<number>) =>
        (typeof control?.value === 'number' && (control?.value < min || control?.value > max) ?
            { range: { min, max } } : null);
};

export const callTypeRangeValidator = (allowPlus: boolean, differentLengthRanges: boolean, prefix: boolean) => {
    return (control: AbstractControl<string>) => {
        const maxLen = prefix ? 255 : 8;
        let reg = new RegExp('^(\\+)?(\\d){0,' + maxLen + '}$');
        if (!allowPlus) {
            reg = new RegExp('^(\\d){1,' + maxLen + '}$');
        }

        const val = control.value.trim();
        if (!val) {
            return { };
        }

        const splitted = val.split(',');
        for (let current of splitted) {
            current = current.trim();

            const range = current.split('-');

            if (range.length === 1) {
                if (!reg.test(current)) {
                    return { invalid: true };
                }
                continue;
            }
            if (range.length !== 2) {
                return { invalid: true };
            }

            const start = range[0].trim();
            const end = range[1].trim();

            if (!differentLengthRanges && (start.length !== end.length)) {
                return { invalid: true };
            }

            if (start.length > maxLen) {
                return { invalid: true };
            }

            if ((!reg.test(start)) || (!reg.test(end))) {
                return { invalid: true };
            }

            const startInt = Number.parseInt(start, 10);
            const endInt = Number.parseInt(end, 10);

            if (startInt >= endInt) {
                return { invalidRange: true };
            }

            if (endInt - startInt > 20) {
                return { rangeCantBeMoreThan20: true };
            }
        }
        return { };
    };
};

export const adminPasswordMinLength = 10;
export const adminPasswordMaxLength = 50;

const passwordWithSpecialCharsValidator: ValidatorFn = ({ value }) => {
    if (!value) {
        // do not check require here
        return null;
    }
    const chars = value.split('');
    const valid =
        value.length >= adminPasswordMinLength &&
        /\d/.test(value) &&
        // require special char for short passwords only to allow google-suggested passwords
        (value.length >= 15 || /[!#$%&()*+,-./:;<=>?@{}]/.test(value)) &&
        chars.indexOf(' ') < 0 &&
        chars.some((x: string) => x === x.toUpperCase() && x !== x.toLowerCase()) &&
        chars.some((x: string) => x === x.toLowerCase() && x !== x.toUpperCase());

    return valid ? null : { passwordWithSpecialChars: true };
};

export const adminPasswordValidator = [
    Validators.required,
    Validators.maxLength(adminPasswordMaxLength),
    passwordWithSpecialCharsValidator,
];

export const MaxCertificateFileSize = 128 * 1024;
export const certificateValidator: ValidatorFn = (control: AbstractControl) => {
    if (control.value) {
        if (control.value.name) {
            if (!/\.pem$/i.test(control.value.name)) {
                return { invalidCertificateFormat: true };
            }
            if (control.value.name.length > 50) {
                return { fileNameSize: true };
            }
        }
        if (control.value.size && control.value.size > MaxCertificateFileSize) {
            return { certificateSize: true };
        }
    }
    return null;
};

export interface UploadFileValidationOptions {
    maxSizeMb?: MaxFileSizeValidation
    accept?: string
    allowedExtensions?: string[]
    incorrectExtensionText?: string
}

export function validateUploadedFile(file: { name: string, size: number }, options: UploadFileValidationOptions = {}): CustomError | string | null {
    const ext = file.name.split('.').pop();
    const { maxSizeMb, allowedExtensions, accept, incorrectExtensionText } = options;
    const extensions = allowedExtensions?.map(ext => ext.replace(/^\./, '')) ?? accept?.split(',').map(part => [...part.matchAll(/^\s*(\.|[a-z]+\/)([a-z]+)\s*$/g)][0]?.[2]).filter(Boolean);

    if (extensions?.length && (!ext || !extensions.includes(ext.toLowerCase()))) {
        return incorrectExtensionText || '_i18n.IncorrectFileFormat';
    }
    if (file.name.length > 50) {
        return '_i18n.FileNameCannotBeMoreThan50';
    }
    if (/^(nul|prn|con|aux|lpt[0-9]|com[0-9])(\.|$)/.test(file.name) || /(^\.|\.$)/.test(file.name) || /["<>|:*?/\\]/.test(file.name)) {
        return '_i18n.InvalidFileName';
    }
    const checkedSize = maxSizeMb || DefaultAllowedNginxSizeMB;
    if (file.size > checkedSize * MB) {
        return fileSizeMbLimitError(checkedSize);
    }
    return null;
}

/** @returns error message (translation key) for invalid file, undefined for valid */
export const validateFileForPromptUpload = (file: File) => validateUploadedFile(file, {
    incorrectExtensionText: '_i18n.IncorrectAudioFormat',
    maxSizeMb: MaxAudioFileSizeMB,
    allowedExtensions: ['wav']
});
