import { Injectable } from '@angular/core';
import {
    BehaviorSubject,
    merge, Observable, ReplaySubject, Subject
} from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {
    distinctUntilChanged,
    filter,
    map,
    scan, share, shareReplay,
    take
} from 'rxjs/operators';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { DeviceService, isWebRTCEndpoint } from '@webclient/phone/device.service';
import { PhoneService } from '@webclient/phone/phone.service';
import { DialCommand } from '@webclient/shared/dial-command';
import { MyCall } from '@webclient/phone/mycall';
import { MyCalls } from '@webclient/phone/mycalls';
import { ModalService } from '@webclient/modal/app-modal.service';
import { List } from 'immutable';
import { LocalConnectionState } from '@myphone';
import { DialerAction } from '@webclient/shared/dialer-action';
import { DialerVisibilityService } from '@webclient/call-adapter/dialer-visibility.service';
import { InCallSearchAction } from '@webclient/call/dialer-utility.service';

@Injectable({
    providedIn: 'root'
})
export class DialerService {
    public readonly currentPhoneNumber: Observable<string|undefined>;

    private readonly phoneNumberEvent: ReplaySubject<string|undefined>;

    public readonly isWebRtcRegistered$: Observable<boolean>;

    public readonly doesNotHaveCalls$ : Observable<boolean>;
    public readonly hasActiveCalls$ : Observable<boolean>;
    public readonly hasSingleCall$ : Observable<boolean>;
    public readonly hasMultipleCalls$ : Observable<boolean>;
    public readonly hasMultipleCallsAllIncoming$ : Observable<boolean>;
    public readonly hasMultipleCallsNotAllIncoming$ : Observable<boolean>;
    public readonly calls$: Observable<List<MyCall>>;

    readonly activeCall$: Observable<MyCall|null>;
    public newPrimaryCallSelected$: Subject<number>;

    private readonly dialerAction = new Subject<DialerAction>();
    public dialerActionEmitted$: Observable<DialerAction>;

    private readonly callSearchAction = new BehaviorSubject<InCallSearchAction>(InCallSearchAction.None);
    public callSearchAction$: Observable<InCallSearchAction>;

    public readonly queryPhoneNumber$: Observable<string>;

    public constructor(
        private route: ActivatedRoute,
        private myPhoneService: MyPhoneService,
        private modalService: ModalService,
        private deviceService: DeviceService,
        private phoneService: PhoneService,
        private dialerVisibilityService: DialerVisibilityService,
    ) {
        this.phoneNumberEvent = new ReplaySubject<string|undefined>(1);

        this.queryPhoneNumber$ = this.route.queryParams.pipe(
            map(params => {
                const phone = params.phone;
                return phone ? phone.replace('tel:', '') : '';
            }),
            filter((number) => !!number)
        );

        this.currentPhoneNumber = this.phoneNumberEvent.pipe(
            share({ connector: () => new ReplaySubject<string|undefined>(1) }),
        );

        this.isWebRtcRegistered$ = this.deviceService.selectedPhoneDevice$.pipe(
            map(selectedDevice => !isWebRTCEndpoint(selectedDevice) || Boolean(selectedDevice && selectedDevice.SourceAddress)),
            distinctUntilChanged()
        );

        this.calls$ = phoneService.myCalls$.pipe(map(myCalls => myCalls.calls));

        this.doesNotHaveCalls$ = this.calls$.pipe(map(callList => !(callList?.size > 0)));
        this.hasActiveCalls$ = this.calls$.pipe(map(callList => callList?.size > 0));
        this.hasSingleCall$ = this.calls$.pipe(map(callList => callList?.size === 1));
        this.hasMultipleCalls$ = this.calls$.pipe(map(callList => callList?.size > 1));
        this.hasMultipleCallsAllIncoming$ = this.calls$.pipe(
            map(callList => (callList && callList.size > 1 && callList.every(call => call.state === LocalConnectionState.Ringing)))
        );
        this.hasMultipleCallsNotAllIncoming$ = this.calls$.pipe(
            map(callList => (callList && callList.size > 1 && !callList.every(call => call.state === LocalConnectionState.Ringing)))
        );

        this.newPrimaryCallSelected$ = new Subject<number>();
        this.activeCall$ = merge(phoneService.myCalls$, this.newPrimaryCallSelected$).pipe(
            scan<MyCalls|number, {calls: MyCalls, selectedCall: MyCall|null}>((acc, value) => {
                if (value instanceof MyCalls) {
                    // New calls come
                    const prevCallId = acc.selectedCall ? acc.selectedCall.myCallId : -1;
                    let newSelectedCall = value.calls.find(call => call.myCallId === prevCallId) ?? value.calls.get(0) ?? null;
                    if (newSelectedCall && value.hasNewCalls) {
                        const dialingCall = value.calls.find(item => item.isTrying);
                        if (dialingCall && !newSelectedCall.isTrying) {
                            newSelectedCall = dialingCall;
                        }
                    }
                    return { calls: value, selectedCall: newSelectedCall };
                }
                else {
                    const newSelectedCall = acc.calls.calls.find(call => call.myCallId === value);
                    // New selector come
                    return { calls: acc.calls, selectedCall: newSelectedCall || acc.selectedCall };
                }
            }, { calls: new MyCalls(), selectedCall: <MyCall|null>null }),
            map(item => item.selectedCall),
            shareReplay({ refCount: true, bufferSize: 1 })
        );

        this.dialerActionEmitted$ = this.dialerAction.asObservable();
        this.callSearchAction$ = this.callSearchAction.asObservable();
    }

    setPrimaryCall(callId: number) {
        this.newPrimaryCallSelected$.next(callId);
    }

    public setTypedPhone = (value?: string) => {
        this.phoneNumberEvent.next(value);
    };

    makeCall(callCommand: DialCommand) {
        this.phoneService.call(callCommand.phoneNumber, callCommand.videoCall);
        this.setTypedPhone();
    }

    leaveVoiceMailNumber(number: string) {
        this.myPhoneService.myPhoneSession.pipe(
            map(session => session.systemParameters.VmailDialCode),
            take(1)
        ).subscribe({
            next: (vmCode) => {
                this.makeCall({ phoneNumber: `${vmCode}${number}`, videoCall: false });
            },
            error: (error: unknown) => {
                this.modalService.error(error);
            },
            complete: () => {
                this.setTypedPhone();
            }
        });
    }

    emitInCallDialerAction(action: DialerAction) {
        this.dialerAction.next(action);

        // show the  dialer if it was not visible
        this.dialerVisibilityService.dialerVisibilityInfo.pipe(take(1)).subscribe((toasterVisibility) => {
            if (!toasterVisibility.isVisible) {
                this.dialerVisibilityService.toggleDialer.next(true);
            }
        });
    }

    setSearchAction(searchAction: InCallSearchAction) {
        this.callSearchAction.next(searchAction);
    }
}
