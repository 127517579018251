import { MaxFileSizeValidation, MB } from '../file-limits';
import { CustomError } from './custom-error';

const units = [
    'bytes',
    'kB',
    'MB',
    'GB',
    'TB',
    'FB'
];

export function fileSizeToString(bytes = 0, precision = 2, defaultWhenZero = '') : string {
    if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) {
        return '?';
    }
    if (bytes === 0) {
        return defaultWhenZero;
    }
    let unit = 0;
    while (bytes >= 1024) {
        bytes /= 1024;
        unit++;
    }
    return bytes.toFixed(+precision) + ' ' + units[unit];
}

export function fileSizeMbLimitError(allowedSizeMB: MaxFileSizeValidation): CustomError {
    return fileSizeLimitError(allowedSizeMB * MB);
}

export function fileSizeLimitError(allowedSizeBytes: number): CustomError {
    return new CustomError('_i18n.FileSizeLimit', { p1: fileSizeToString(allowedSizeBytes, 0) });
}
