import { Injectable } from '@angular/core';
import { ErrorCodes, ResponseAcknowledge } from '@myphone';
import { MessageEncoder } from '@webclient/myphone/message-encoder';
import { Observable } from 'rxjs';
import { myPhoneEndPoint, MyphoneHeaders } from '@webclient/myphone/myphone-header.func';
import { map } from 'rxjs/operators';
import { IDataTransport } from '@webclient/myphone/i-data-transport';
import { basePost } from '@webclient/myphone/base-post.func';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import {
    convertAcknowledgeErrorType
} from '@webclient/convert-server-error';

// classic data transport service
@Injectable()
export class DataTransportService implements IDataTransport {
    constructor(private _encoder: MessageEncoder, private httpClient: HttpClient, private translateService: TranslateService) {
    }

    protected transportImplementation(request: Uint8Array, headers: MyphoneHeaders, domain: string): Observable<any> {
        return this.httpClient.post(new URL(myPhoneEndPoint, domain).href,
            request.buffer.slice(request.byteOffset, request.byteLength + request.byteOffset),
            {
                headers,
                responseType: 'arraybuffer'
            });
    }

    public transport<T>(request: any, headers: MyphoneHeaders, domain: string) : Observable<T> {
        if (!environment.production) {
            const name = request.constructor?.name;
            if (name) {
                headers = { ...headers, 'X-Request-Name': name };
            }
        }
        return this.transportImplementation(this._encoder.encode(request), headers, domain).pipe(
            map(buffer => this.processResponse(request, buffer))
        );
    }

    public fetchPost(link: string, content: any, headers: MyphoneHeaders, domain: string): Observable<any> {
        return basePost({
            link,
            basePath: domain,
            body: content,
            headers: new Headers(headers)
        });
    }

    protected processResponse(request : any, buffer: ArrayBuffer) {
        const message = this._encoder.decode(new Uint8Array(buffer));
        if (message instanceof ResponseAcknowledge) {
            const ack = message;
            if (!ack.Success) {
                // Throw if server response contains error
                // We are translating ResponseAcknowledge errors here, because these are the only errors with Parameters
                const errMsg = this.getErrorMessage(ack) || `Received unsuccessful ack for ${request.constructor.name}`;
                const error = new Error(errMsg);
                (error as any).source = ack;
                throw error;
            }
        }
        return message;
    }

    private getErrorMessage(ack: ResponseAcknowledge): string {
        const paramsObj: {[key: string]: string} = {};
        ack.Parameters?.forEach((str, index) => {
            paramsObj[`p${index + 1}`] = str;
        });

        if (ack.IsCustomMessage) {
            return ack.Message;
        }

        // Special case for WebMeetingInvalidParticipant with 2 parameters, using different translation key.
        if (ack.ErrorType === ErrorCodes.WebMeetingInvalidParticipant && ack.Parameters?.length === 2) {
            return this.translateService.instant('_i18n.WebMeetingInvalidParticipantWithError', paramsObj);
        }

        return this.translateService.instant(convertAcknowledgeErrorType[ack.ErrorType], paramsObj);
    }
}
