import { Inject, Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { MessageEncoder } from './message-encoder';
import { MyPhoneSession } from './myphone-session';
import { ChatMessage } from '../chat/chat-service/chat-message';
import { ActivatedRoute, Router } from '@angular/router';
import { JoinTool } from './join/join';
import { GroupsJoin } from './join/groups-join';
import { QueuesJoin } from './join/queues-join';
import { startNotificationChannel } from './notification-channel';
import { WEB_SOCKET_FACTORY, WebSocketFactory } from './websocket-factory';
import {
    ActionType,
    AnonymousSessionClosed,
    ChatMonitoringEvent,
    ChatTyping,
    Conferences,
    DnType,
    Groups,
    IVRs,
    Logout,
    MeetingStateNotification,
    MyExtensionInfo,
    MyWebRTCEndpoint,
    NotificationChatFileProgress,
    NotificationChatMessageStatus,
    NotificationChatTransferred,
    NotificationConversationRemoved,
    Parkings,
    Queues,
    ResponseAvailableProviders,
    ResponseContactChanged,
    ResponseConversationInfo, ResponseExtensionsChanged,
    ResponseLookup,
    ResponseMyMessages,
    ResponseServerTime,
    ResponseSystemParameters,
    ResponseUnreadMessagesCount
} from '@myphone';
import { deepCopy, Logger, MY_PHONE_LOG } from './logger';
import {
    concat,
    connectable,
    merge,
    NEVER,
    Observable,
    of,
    race,
    ReplaySubject,
    retry,
    Subject,
    Subscription,
    tap,
    timer
} from 'rxjs';
import { finalize, map, switchMap, take, takeWhile } from 'rxjs/operators';
import { LogoutApiService, MyPhoneApiService } from '@api';
import { DataTransportService } from '@webclient/myphone/data-transport.service';
import { GenericMessageType } from '@webclient/shared/myphone-types';
import { loginFingeprint } from '@webclient/worker/fingerprint';
import { ConnectableObservableLike } from '@webclient/myphone/connectableObservableLike';
import { ExtendedSwPushService } from '@webclient/notifications/extended-sw-push.service';
import { noEmitAndConsoleWarnOnError } from '@webclient/rx-utils';
import { LocalStorageKeys } from '@webclient/settings/local-storage-keys';
import { LocalStorageService } from 'ngx-webstorage';
import { TokenService } from '@webclient/auth/token.service';
import { ImmediateReconnectError } from '@webclient/myphone/immediate-reconnect-error';
import { basePathFactory } from '@webclient/app-tokens';

export const maxRetryTimeout = 60000;
export const minRetryTimeout = 5000;
export const fixedRetryTimeout = 10000;

export function retryTimeoutMs(): number {
    if (maxRetryTimeout < minRetryTimeout) {
        throw new Error('maxRetryTimeout cannot be less than minRetryTimeout');
    }
    const randomValue = Math.floor(Math.random() * (maxRetryTimeout - minRetryTimeout));
    return minRetryTimeout + randomValue;
}

@Injectable()
export class MyPhoneService {
    public retryNow$ = new Subject<void>();
    public reconnectTime = 0;
    public reconnectAttempt = 0;
    public isReconnecting = false;
    // Returns session if one exists
    public readonly myPhoneSession: Observable<MyPhoneSession>;
    private readonly _myPhoneSession: ConnectableObservableLike<MyPhoneSession>;
    private _logout = false;
    public readonly isPro$: Observable<boolean>;
    public readonly licenseHasHotelFeatures$: Observable<boolean>;
    public readonly licenseHasWebinarFeatures$: Observable<boolean>;
    public readonly hasQueues$: Observable<boolean>;
    private connectStartTime: number|undefined;
    public myPhoneSessionInit$ = new Subject<boolean>();

    constructor(private http: HttpClient, private router: Router, private zone: NgZone, private _encoder: MessageEncoder,
        private myPhoneApiService: MyPhoneApiService,
        private logoutApiService: LogoutApiService,
        private dataTransport: DataTransportService,
        private swPush: ExtendedSwPushService,
        private localStorageService: LocalStorageService,
        private tokenService: TokenService,
        @Inject(WEB_SOCKET_FACTORY)webSocketFactory : WebSocketFactory, private logger: Logger, private route: ActivatedRoute,
    ) {
        const login = () => {
            // We're not authenticated
            this.gotoLogin();
            return NEVER;
        };

        this._myPhoneSession = connectable(this.myPhoneApiService.myPhoneSession(loginFingeprint).pipe(
            retry({
                delay: error => {
                    // First reconnect immediately
                    if (this.isReconnecting && this.reconnectAttempt === 1) {
                        this.reconnectAttempt += 1;
                        return of(true);
                    }

                    this.reconnectAttempt += 1;
                    this.isReconnecting = true;

                    // Tell user what's happened
                    console.log(error);

                    // Nothing can be done just go to login
                    if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403)) {
                        return login();
                    }
                    // Clientside error
                    else if (error instanceof HttpErrorResponse && error.status === 0) {
                        this.isReconnecting = true;
                        return this.retryWithFixedDelay();
                    }

                    // An error happened but we can retry connection
                    return this.retryWithRandomDelay();
                }
            }),
            switchMap(data => {
            // Time to create new session
                this.connectStartTime = Date.now();
                const session = new MyPhoneSession(data, logger, this.dataTransport, basePathFactory());
                // Process messages from notification channel to a session
                return merge(
                    session.errorDetected$,
                    this.processMyPhoneMessages(session, startNotificationChannel(zone, this._encoder, session, webSocketFactory))
                );
            }),
            // Retry if any error occurred
            retry({
                delay: error => {
                    this.reconnectAttempt += 1;
                    this.isReconnecting = true;

                    // Tell user what's happened
                    console.log(error);

                    const retryInvoker = (error instanceof ImmediateReconnectError || this.reconnectAttempt === 1) ?
                        of<0>(0) : this.retryWithRandomDelay();

                    return retryInvoker.pipe(takeWhile(() => !this._logout));
                }
            }),
            tap(() => {
                this.isReconnecting = false;
                this.reconnectAttempt = 0;
            }),
            finalize(() => {
                this.isReconnecting = false;
                this.reconnectAttempt = 0;
            })),
        {
            connector: () => new ReplaySubject<MyPhoneSession>(1)
        });

        this.myPhoneSession = this._myPhoneSession;

        this.isPro$ = this.myPhoneSession.pipe(map(session => session.isPro()));

        this.licenseHasHotelFeatures$ = this.myPhoneSession.pipe(map(session => session.licenseHasHotelFeatures()));
        this.licenseHasWebinarFeatures$ = this.licenseHasHotelFeatures$;

        this.hasQueues$ = this.myPhoneSession.pipe(switchMap(session => session.queues$), map(queues => Object.keys(queues).length > 0));
    }

    sessionSubscription?: Subscription;

    private retryWithRandomDelay() {
        const timeout = retryTimeoutMs();
        this.reconnectTime = Date.now() + timeout;
        return race([timer(timeout), this.retryNow$]).pipe(
            tap(() => {
                this.reconnectTime = 0;
            })
        );
    }

    private retryWithFixedDelay() {
        this.reconnectTime = Date.now() + fixedRetryTimeout;
        return timer(fixedRetryTimeout).pipe(
            tap(() => {
                this.reconnectTime = 0;
            })
        );
    }

    public connect() {
        if (!this.sessionSubscription) {
            this._logout = false;
            this.sessionSubscription = this._myPhoneSession.connect();
            // Now we're connected
            this.myPhoneSessionInit$.next(true);
        }
    }

    public disconnect() {
        this.sessionSubscription?.unsubscribe();
        this.sessionSubscription = undefined;
    }

    gotoLogin() {
        this.tokenService.invalidate();
        this._logout = true;
        const redirectUrl = window.location.hash?.startsWith('#/office') ? {
            queryParams: {
                next: window.location.hash.replace('#/office', '/office')
            }
        } : {};
        this.router.navigate(['/login'], redirectUrl);
    }

    // Create notification channel
    public logout() {
        this._logout = true;
        this.myPhoneSession.pipe(
            take(1),
            switchMap(session => {
                return concat(
                    this.swPush.pushUnsubscribe(session),
                    session.get(new Logout()).pipe(noEmitAndConsoleWarnOnError('Logout')),
                    this.logoutApiService.logoutGet().pipe(noEmitAndConsoleWarnOnError('Webclient logout'))
                );
            })
        ).subscribe({
            complete: () => {
                // Invalidate token here so we can properly send logout
                this.localStorageService.store(LocalStorageKeys.LogoutTimestamp, new Date().getTime());
                this.gotoLogin();
            },
        });
    }

    public get<T extends GenericMessageType>(request: GenericMessageType): Observable<T> {
        return this.myPhoneSession.pipe(take(1), switchMap(session => session.get<T>(request)));
    }

    public httpPost<T>(requestUrl: string, body: any): Observable<T> {
        return this.myPhoneSession.pipe(take(1), switchMap(session => session.fetchPost(requestUrl, body)));
    }

    private processMyPhoneMessages(myPhoneSession: MyPhoneSession, source: Observable<any[]>): Observable<MyPhoneSession> {
        let myExtensionInfoReceived = false;
        let timeReceived = false;
        let groupsReceived = false;
        let myPhoneSessionReported = false;

        return new Observable((subscriber: any) => source.subscribe({
            next: (messageBuffer: object[]) => {
                try {
                    messageBuffer.forEach(message => {
                        if (this.logger.logEnabled) {
                            const proto = Object.getPrototypeOf(message);
                            if (proto.typeName) {
                                this.logger.showInfo({
                                    loggingLevel: MY_PHONE_LOG,
                                    typeName: proto.typeName,
                                    optionalParams: [deepCopy(message)]
                                });
                            }
                        }

                        MyPhoneService.join(myPhoneSession, message);
                        if (!myPhoneSessionReported) {
                            // MyExtensionInfo received
                            if (message instanceof MyExtensionInfo) {
                                myExtensionInfoReceived = true;
                            }
                            // Groups received
                            else if (message instanceof Groups && message.FromLocalPbx) {
                                groupsReceived = true;
                            }
                            else if (message instanceof ResponseServerTime) {
                                timeReceived = true;
                            }
                            if (myExtensionInfoReceived && groupsReceived && timeReceived) {
                                if (this.logger.logEnabled) {
                                    this.logger.showInfo({
                                        loggingLevel: MY_PHONE_LOG,
                                        message: `Session initiated in ${(Date.now() - (this.connectStartTime ?? 0)) / 1000}`
                                    });
                                }
                                this.isReconnecting = false;
                                myPhoneSessionReported = true;
                                subscriber.next(myPhoneSession);
                            }
                        }
                    });
                }
                catch (error) {
                // MyPhone session is inactive now and we will retry a connection
                    if (myPhoneSession !== undefined) {
                        myPhoneSession.deactivate();
                    }
                    subscriber.error(error);
                }
            },
            error: (err: unknown) => {
                if (myPhoneSession !== undefined) {
                    myPhoneSession.deactivate();
                }
                subscriber.error(err);
            },
            complete: () => subscriber.complete()
        }));
    }

    public static join(session: MyPhoneSession, update: unknown) {
        if (update instanceof MyExtensionInfo) {
            if (update.Action === ActionType.FullUpdate) {
                session.fullUpdate.myExtensionInfo = true;
            }
            if (!session.fullUpdate.myExtensionInfo) {
                return;
            }
            const removedConn = update.Connections?.Items?.filter(conn => conn.Action === ActionType.Deleted) ?? [];
            if (removedConn.length > 0) {
                session.connectionsDeleted$.next(removedConn);
            }
            JoinTool.Merge(session.myInfo, update);
            session.myInfo$.next(session.myInfo);

            session.activeCalls.ACUpdateMyExtensionInfo(update);
        }
        else if (update instanceof ResponseSystemParameters) {
            if (update.PhonebookMinMatch !== undefined) {
                session.cache.deleteBrunch('RequestLookupContact');
            }
            JoinTool.MergePlainObject(session.systemParameters, update);
            session.systemParameters$.next(session.systemParameters);
        }
        else if (update instanceof Groups) {
            new GroupsJoin(session).Merge(update);
            session.activeCalls.ACUpdateGroups(update);
        }
        else if (update instanceof Queues) {
            if (update.Action === ActionType.FullUpdate) {
                session.fullUpdate.queues = true;
            }
            if (!session.fullUpdate.queues) {
                return;
            }

            new QueuesJoin(session).Merge(update);
            session.onQueuesIdentityChanged();
        }
        else if (update instanceof Parkings) {
            if (update.Action === ActionType.FullUpdate) {
                session.fullUpdate.parkings = true;
                session.parkings.Items = [];
            }
            if (!session.fullUpdate.parkings) {
                return;
            }

            JoinTool.Merge(session.parkings, update);
            session.parkings$.next(session.parkings);
            session.activeCalls.ACUpdateParkings(update);
        }
        else if (update instanceof IVRs) {
            if (update.Action === ActionType.FullUpdate) {
                session.fullUpdate.ivrs = true;
            }
            if (!session.fullUpdate.ivrs) {
                return;
            }

            session.activeCalls.ACUpdateIVRs(update);
        }
        else if (update instanceof ResponseContactChanged) {
            session.cache.deleteBrunch('RequestLookupContact');
            if (update.Update.Action === ActionType.Deleted) {
                session.contactDeleted$.next(update.Update);
            }
            else {
                session.contactUpdated$.next(update.Update);
            }
        }
        else if (update instanceof ResponseExtensionsChanged) {
            session.cache.deleteBrunch('RequestLookupContact');
        }
        else if (update instanceof ResponseMyMessages) {
            session.newChatMessages$.next(update.Messages
                .map(m => new ChatMessage(m)));
        }
        else if (update instanceof NotificationChatMessageStatus) {
            session.chatMessageState$.next(update);
        }
        else if (update instanceof ResponseAvailableProviders) {
            session.availableProviders$.next(update);
        }
        else if (update instanceof AnonymousSessionClosed) {
            session.anonymousSessionClosed.next(update);
        }
        else if (update instanceof ResponseUnreadMessagesCount) {
            session.unreadChatCount$.next(update.Items);
        }
        else if (update instanceof NotificationChatFileProgress) {
            session.messageDownloadProgress$.next(update);
        }
        else if (update instanceof ChatTyping) {
            session.userTyping$.next(update);
        }
        else if (update instanceof MeetingStateNotification) {
            if (update.IsQuickMeeting) {
                session.quickMeetingActive$.next(update.State === 1);
            }
        }
        else if (update instanceof ChatMonitoringEvent) {
            session.chatMonitoringEvent$.next(update);
        }
        else if (update instanceof NotificationConversationRemoved) {
            session.conversationRemoved$.next(update);
        }
        else if (update instanceof NotificationChatTransferred) {
            session.chatTransferred$.next(update);
        }
        else if (update instanceof ResponseConversationInfo) {
            session.conversationInfo$.next(update);
        }
        else if (update instanceof Conferences) {
            if (update.Action === ActionType.FullUpdate) {
                session.fullUpdate.conferences = true;
            }
            if (!session.fullUpdate.conferences) {
                return;
            }

            JoinTool.MergePlainObject(session.conferences, update);
            session.conferences$.next(session.conferences);
        }
        else if (update instanceof ResponseServerTime) {
            session.timeSync.refresh(update);
        }
        else if (update instanceof MyWebRTCEndpoint) {
            JoinTool.Merge(session.webRTCEndpoint, update);
            session.onWebRTCChanged();
        }
        else if (update instanceof ResponseLookup) {
            const conference = update.Entries.find(item => item.DnType === DnType.Conference);
            const specialMenu = update.Entries.find(item => item.DnType === DnType.SpecialMenu);
            if (conference) {
                session.conferenceGateway = conference.ExtensionNumber;
            }
            if (specialMenu) {
                session.specialMenuNumber = specialMenu.ExtensionNumber;
            }
        }
    }
}
