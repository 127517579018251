import { AbstractControl } from '@angular/forms';
import { click2TalkMaxLength } from '@webclient/validators';
import {
    PbxEntityRestrictions,
    PbxEventLogType,
    PbxIVRType,
    PbxPollingStrategyType,
    PbxSRTPModeType,
    PbxStrategyType, PbxTranscriptionType,
    PbxUpdateType
} from '@xapi';
import { notEmpty } from '@webclient/phone/phone.helpers';
import { CheckOption } from '@webclient/fields/types';

export const Wtf = '???';

export const UnlimitedItems = -1;

export const srtpOptions: CheckOption<PbxSRTPModeType> [] = [
    {
        value: PbxSRTPModeType.SrtpDisabled,
        label: '_i18n.Disabled',
    },
    {
        value: PbxSRTPModeType.SrtpEnabled,
        label: '_i18n.Enabled',
    },
    {
        value: PbxSRTPModeType.SrtpEnforced,
        label: '_i18n.SrtpEnforced',
    }
];

export function isNewItemAllowed(restr: PbxEntityRestrictions|null|undefined) {
    if (!restr) {
        return false;
    }
    return restr.Allowed === UnlimitedItems || (restr.Used! < restr.Allowed!);
}

export function constructClickToCallUrl(systemUrl: string | null, clickToCallId:string | null) {
    return `https://${systemUrl}/${clickToCallId ?? ''}`;
}

export function constructWebMeetingFriendlyNameUrl(system: string, WebMeetingFriendlyName:string) {
    return `https://${system}/meet/${WebMeetingFriendlyName}`;
}

export function getDefaultFriendlyName(name: string | null | undefined, lastname?: string | null): string {
    return ((name ?? '') + (lastname ?? ''))
        .toLowerCase()
        .trim()
        .replace(/[^a-zA-Z0-9]/g, '')
        .substring(0, click2TalkMaxLength);
}

function isUnique<T, F>(control: AbstractControl<(T|null)[]>, extractKey: (val: T) => F) {
    const members = control.value?.filter(notEmpty);
    return members && (new Set(members.map(extractKey)).size !== members.length) ? {
        duplicateMembers: true
    } : null;
}

export const uniqueNumbersValidator = (control: AbstractControl<((string|null)|null)[]>) => isUnique(control, item => item);

export const uniquePeersValidator = (control: AbstractControl<({ Number: string|null }|null)[]>) => isUnique(control, item => item.Number);

export function getRingStrategyTranslation(ringStrategy: PbxStrategyType|null|undefined): string {
    switch (ringStrategy) {
        case 'Hunt':
            return '_i18n.PrioritizedHunt';
        case 'RingAll':
            return '_i18n.RingAll';
        case 'Paging':
            return '_i18n.Paging';
        default:
            return Wtf;
    }
}

export function getPollingStrategyTypeTranslation(pollingStrategy: PbxPollingStrategyType|null|undefined): string {
    switch (pollingStrategy) {
        case 'Hunt':
            return '_i18n.PrioritizedHunt';
        case 'RingAll':
            return '_i18n.RingAll';
        case 'HuntRandomStart':
            return '_i18n.HuntRandomStart';
        case 'NextAgent':
            return '_i18n.RoundRobin';
        case 'LongestWaiting':
            return '_i18n.LongestWaiting';
        case 'LeastTalkTime':
            return '_i18n.LeastTalkTime';
        case 'FewestAnswered':
            return '_i18n.FewestAnswered';
        case 'HuntBy3s':
            return '_i18n.HuntBy3s';
        case 'First3Available':
            return '_i18n.HuntBy3sPrioritized';
        case 'SkillBasedRouting_RingAll':
            return '_i18n.SkillBasedRoutingRingAll';
        case 'SkillBasedRouting_HuntRandomStart':
            return '_i18n.SkillBasedRoutingHuntRandomStart';
        case 'SkillBasedRouting_RoundRobin':
            return '_i18n.SkillBasedRoutingRoundRobin';
        case 'SkillBasedRouting_FewestAnswered':
            return '_i18n.SkillBasedRoutingFewestAnswered';
        default:
            return Wtf;
    }
}

export function getIvrTypeTranslation(ivrType: PbxIVRType|null|undefined): string {
    switch (ivrType) {
        case 'Default':
            return '_i18n.Standard';
        case 'CodeBased':
            return '_i18n.DtmfInput';
        case 'ScriptBased':
            return '_i18n.LaunchScript';
        case 'Wakeup':
            return '_i18n.WakeUp';
        default:
            return Wtf;
    }
}

export function getEventLogTypeTranslation(eventLogType: PbxEventLogType|null|undefined) : string {
    switch (eventLogType) {
        case 'Info':
            return '_i18n.Info';
        case 'Warning':
            return '_i18n.Warning';
        case 'Error':
            return '_i18n.Error';
        default:
            return Wtf;
    }
}

export function getInstallationTypeTranslation(IsHosted3CX: boolean | null | undefined, IsMulticompanyMode: boolean | null | undefined, IsHosted: boolean | null | undefined) {
    if (IsHosted3CX && IsMulticompanyMode) {
        return '_i18n.StartUp';
    }
    else if (IsHosted3CX) {
        return '_i18n.3CXHosted';
    }
    else if (IsHosted) {
        return '_i18n.SelfHosted';
    }
    else {
        return '_i18n.OnPremise';
    }
}

export function getVersion(version: string | null | undefined, updateType?: PbxUpdateType | null) {
    if (version) {
        const verArray = version.split('.');
        const type = getUpdateType(updateType);
        return `${verArray[0]}.${verArray[1]} Update ${verArray[2]} (Build ${verArray[3]}${type ? ' ' + type : ''})`;
    }
    return null;
}

// Shows only the major and the minor part because from the respect of ERP only those matter
export function getErpVersion(version: string | null | undefined) {
    if (version) {
        const verArray = version.split('.');
        return `${verArray[0]}.${verArray[1]}`;
    }
    return null;
}

export function getUpdateType(updateType: PbxUpdateType | null | undefined) : string {
    return updateType
        ? updateType === PbxUpdateType.MajorRelease
            ? 'Major Release'
            : updateType
        : '';
}

export function inboundRuleName(number: string, name?: string | null) {
    return number + (name ? ` (${name})` : '');
}

export function getTypeTranslation(type: PbxTranscriptionType): string {
    switch (type) {
        case PbxTranscriptionType.Nothing:
            return '_i18n.Off';
        case PbxTranscriptionType.Voicemail:
            return '_i18n.TranscribeLevelVoicemailsOnly';
        case PbxTranscriptionType.Recordings:
            return '_i18n.TranscribeLevelRecordingsOnly';
        case PbxTranscriptionType.Both:
            return '_i18n.TranscribeLevelBoth';
        default:
            return '';
    }
}
