import { ProfileNames } from '@webclient/myphone/profile';
import type { ThemeType } from '@webclient/services/theme-listener.service';
import type { CrmVariableValue } from '@webclient/settings/integrations/crm-integration-interface';

export enum CrmLookUpMode {
    Manual = 0,
    Ringing = 1,
    Connected = 2
}

export enum ChatNotifications {
    ShowBoth,
    Show3CXOnly,
    ShowWebOnly
}

export enum LaunchIntegrationWhen {
    Ringing = 0,
    Connected = 1
}

export enum AutoOpenUrl {
    Off = 0,
    CrmUrl = 1,
    IntegrationsUrl = 2,
    ClientCrmUrl = 3
}

export class GeneralSettings {
    constructor(init?: Partial<GeneralSettings> | null) {
        Object.assign(this, init);
    }

    public applicationTheme: ThemeType = 'light';
    public useIdleStatus = false;
    public idleStatusTime = 5;
    public idleStatus = ProfileNames.Away;
    public wallboardCustomMessage1 = '3CX Wallboard';
    public wallboardCustomMessage2: string;
    public playNewChatMessageSound: boolean;
    public showChatToaster = true;
    public callLookup = CrmLookUpMode.Manual;
    public crmName = '';
    public crmVariables: CrmVariableValue[] = [];
    public launchIntegrationWhen: LaunchIntegrationWhen = LaunchIntegrationWhen.Ringing;
    public isTapiEnabled = false;
    /**
     * @deprecated
     */
    public showChatNotifications: ChatNotifications = ChatNotifications.ShowBoth;
    public chatSoundFile = 'Default';
    public expandPhoneBookPlusEnabled: boolean;
    public expandPhoneBookPlus = '00';
    public autoOpenUrl: AutoOpenUrl = AutoOpenUrl.Off;
    public integrationsUrl = 'http://example.com/search?phoneNumber=%CallerNumber%&displayName=%CallerDisplayName%';
    public launchExternalApplication: boolean;
    public launchExternalPath: string;
    public launchExternalParameters = '%CallerNumber% %CallerDisplayName%';
    public launchExternalWhen: LaunchIntegrationWhen = LaunchIntegrationWhen.Ringing;
}
