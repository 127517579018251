import type { AppContact } from '../contact';

import { fitsPhoneNumber } from '../../phone/phone-funcs';
import { String } from '../../shared/string.utils';
import { ProfileNames } from '@webclient/myphone/profile';

const colors = [
    '#458f3f',
    '#4d72d1',
    '#3ca1c2',
    '#5c6bc0',
    '#3c3cc2',
    '#9c6b2b',
    '#aa47bc'
];

export const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}]+/ug);

export class Utils {
    public static onContactNameChanged(contact: AppContact) {
        if (!contact.firstName || !contact.lastName) {
            contact.firstNameLastName = contact.firstName || contact.lastName || contact.company || '';
            contact.lastNameFirstName = contact.firstNameLastName;
        }
        else {
            contact.firstNameLastName = Utils.getFirstNameLastName(contact);
            contact.lastNameFirstName = Utils.getLastNameFirstName(contact);
        }
    }

    public static getFirstNameLastName(contact: {firstName: string; lastName: string}): string {
        return contact.firstName && contact.lastName
            ? contact.firstName + ' ' + contact.lastName
            : contact.firstName || contact.lastName || '';
    }

    public static getLastNameFirstName(contact: {firstName: string; lastName: string}): string {
        return contact.firstName && contact.lastName
            ? contact.lastName + ', ' + contact.firstName
            : contact.firstName || contact.lastName || '';
    }

    public static getProfileCssClass(profileName: string | null | undefined): string {
        switch (profileName) {
            case ProfileNames.Available:
                return 'available';
            case ProfileNames.Away:
                return 'away';
            case ProfileNames.OutOfOffice:
                return 'out_of_office';
            case ProfileNames.Custom1:
                return 'custom1';
            case ProfileNames.Custom2:
                return 'custom2';
            default:
                return 'off';
        }
    }

    public static filterObjectUsingText<T>(objects: T[], text: string): T[] {
        if (!objects) {
            return []; // TODO: better to throw an exception I think
        }

        // // return all in case of empty filter
        if (!text) {
            return objects;
        }

        // shortcut for start with
        function sw(str?: string) {
            if (str === undefined) {
                return false;
            }
            return str.toLowerCase().indexOf(text.toLowerCase()) >= 0;
        }

        function searchInObject(obj: any): boolean {
            if (obj === undefined) {
                return false;
            }
            if (typeof obj !== 'object') {
                return sw(obj.toString());
            }
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const val = obj[key];
                    if (!val || val instanceof Array) {
                        continue;
                    }
                    const res = searchInObject(val);
                    if (res) {
                        return res;
                    }
                }
            }
            return false;
        }

        const result = [];
        for (const object of objects) {
            if (searchInObject(object)) {
                result.push(object);
            }
        }
        return result;
    }

    public static filterContactUsingText(objects: AppContact[], text: string): AppContact[] {
        function sw(str?: string) {
            if (str === undefined) {
                return false;
            }
            return str.toLowerCase().indexOf(text.toLowerCase()) >= 0;
        }

        if (String.isNullOrEmpty(text)) {
            return objects;
        }

        const result = [];
        for (const object of objects) {
            if (sw(object.firstNameLastName) ||
                sw(object.lastNameFirstName) ||
                sw(object.company) ||
                sw(object.emailAddress) ||
                fitsPhoneNumber(object.sanitizedPhones, text)) {
                result.push(object);
            }
        }
        return result;
    }
}
