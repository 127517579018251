import { Pipe, PipeTransform } from '@angular/core';
import { fileSizeToString } from '@webclient/file-size-utils';

/*
 * Convert bytes into largest possible unit.
 * Takes an precision argument that defaults to 2.
 * Usage:
 *   bytes | fileSize:precision
 * Example:
 *   {{ 1024 |  fileSize}}
 *   formats to: 1 KB
*/
@Pipe({ name: 'fileSize', standalone: true })
export class FileSizePipe implements PipeTransform {
    readonly transform = fileSizeToString;
}
