import { SortedContactsMap } from './sorted-contacts-map';
import type { Group } from './group';
import type { AppContact } from './contact';
import type { ExtensionBridge } from './extension-bridge';
import { ReplaySubject } from 'rxjs';

export type BridgesMap = {[id: string]: ExtensionBridge};
export type GroupsMap = {[id: string]: Group};
export type ContactsMap = {[id: string]: AppContact};

export class Bridge {
    public readonly id: string;
    public name = ''; // Default value to prevent orderBy filter crash
    public prefix = '';
    public isConnected: boolean;
    public isAllowed: boolean;

    public readonly groupsMap: GroupsMap = {};
    public contactsMap: SortedContactsMap = new SortedContactsMap();
    // On contacts changed
    public contactsMap$: ReplaySubject<SortedContactsMap> = new ReplaySubject(1);

    // On groups changed
    public groupsMap$: ReplaySubject<GroupsMap> = new ReplaySubject(1);

    isHidden = false; // Ui property - stored in local storage

    constructor(id: string) {
        this.id = id;
        this.onContactsChanged();
    }

    public onContactsChanged() {
        this.contactsMap$.next(this.contactsMap);
    }

    public onGroupChanged() {
        this.groupsMap$.next(this.groupsMap);
    }

    // Get unique contacts from all groups
    get contacts(): AppContact[] {
        return this.contactsMap.getSortedContacts();
    }

    // Get all groups
    get groups(): Group[] {
        return Object.values<Group>(this.groupsMap);
    }
}
