import { ErrorCodes } from '@myphone';

export const convertAcknowledgeErrorType: Record<ErrorCodes, string> = {
    [ErrorCodes.ConferenceWithPinDoesNotExist]: '_i18n.ConferenceWithPinDoesNotExist',
    [ErrorCodes.ConferenceWithPinAlreadyExists]: '_i18n.ConferenceWithPinAlreadyExists',
    [ErrorCodes.ConferencePinAndIdDoesNotMatch]: '_i18n.ConferencePinAndIdDoesNotMatch',
    [ErrorCodes.ConferenceAccessDenied]: '_i18n.ConferenceAccessDenied',
    [ErrorCodes.ConferenceIsCancelled]: '_i18n.ConferenceIsCancelled',
    [ErrorCodes.ConferencePinIsReadOnly]: '_i18n.ConferencePinIsReadOnly',
    [ErrorCodes.ConferenceInvalidPin]: '_i18n.ConferenceInvalidPin',
    [ErrorCodes.CannotGeneratePin]: '_i18n.CannotGeneratePin',
    [ErrorCodes.FwdProfileDoesNotExist]: '_i18n.FwdProfileDoesNotExist',
    [ErrorCodes.FwdProfileOverrideExpirationRequired]: '_i18n.FwdProfileOverrideExpirationRequired',
    [ErrorCodes.Success]: '_i18n.Success',
    [ErrorCodes.NoSuchRequest]: '_i18n.NoSuchRequest',
    [ErrorCodes.ExceptionOccured]: '_i18n.ExceptionOccurred',
    [ErrorCodes.RequestIsNotSupported]: '_i18n.RequestIsNotSupported',
    [ErrorCodes.ServerIsBusy]: '_i18n.ServerIsBusy',
    [ErrorCodes.BridgeNotFound]: '_i18n.BridgeNotFoundAck',
    [ErrorCodes.CannotCleanOwnExtension]: '_i18n.CannotCleanOwnExtension',
    [ErrorCodes.SetWakeupCallResult]: '_i18n.SetWakeupCallResult',
    [ErrorCodes.ExtensionNotFound]: '_i18n.ExtensionNotFound',
    [ErrorCodes.NoPermission]: '_i18n.NoPermission',
    [ErrorCodes.WebMeetingNoEmail]: '_i18n.WebMeetingNoEmail',
    [ErrorCodes.WebMeetingNoAccess]: '_i18n.WebMeetingNoAccess',
    [ErrorCodes.WebMeetingInvalidOrganizer]: '_i18n.WebMeetingInvalidOrganizer',
    [ErrorCodes.WebMeetingInvalidParameters]: '_i18n.WebMeetingInvalidParameters',
    [ErrorCodes.WebMeetingInvalidParticipant]: '_i18n.WebMeetingInvalidParticipant',
    [ErrorCodes.WebMeetingInvalidPin]: '_i18n.WebMeetingInvalidPin',
    [ErrorCodes.WebMeetingAccessDenied]: '_i18n.WebMeetingAccessDenied',
    [ErrorCodes.WebMeetingNotFound]: '_i18n.WebMeetingNotFound',
    [ErrorCodes.WebMeetingCannotDeleteQM]: '_i18n.WebMeetingCannotDeleteQM',
    [ErrorCodes.WebMeetingPinIsReadonly]: '_i18n.WebMeetingPinIsReadonly',
    [ErrorCodes.WebMeetingNumberToCallIsReadonly]: '_i18n.WebMeetingNumberToCallIsReadonly',
    [ErrorCodes.WebMeetingInvalidWmUser]: '_i18n.WebMeetingInvalidWmUser',
    [ErrorCodes.ExtensionEmailRequired]: '_i18n.ExtensionEmailRequired',
    [ErrorCodes.QueueNumberRequired]: '_i18n.QueueNumberRequired',
    [ErrorCodes.ChatIsDisabled]: '_i18n.ChatIsDisabled',
    [ErrorCodes.PersonalContactRequired]: '_i18n.PersonalContactRequired',
    [ErrorCodes.RequiredFieldIsEmpty]: '_i18n.RequiredFieldIsEmpty',
    [ErrorCodes.ContactNotFound]: '_i18n.ContactNotFound',
    [ErrorCodes.ContactIsReadonly]: '_i18n.ContactIsReadonly',
    [ErrorCodes.ActionIsNotAllowed]: '_i18n.ActionIsNotAllowed',
    [ErrorCodes.FileNotFound]: '_i18n.FileNotFoundAck',
    [ErrorCodes.OwnRecordingsDenied]: '_i18n.OwnRecordingsDenied',
    [ErrorCodes.InvalidValue]: '_i18n.InvalidValue',
    [ErrorCodes.InvalidMedia]: '_i18n.InvalidMedia',
    [ErrorCodes.InvalidOperation]: '_i18n.InvalidOperation',
    [ErrorCodes.OperationFailed]: '_i18n.OperationFailedAck',
    [ErrorCodes.StillProcessing]: '_i18n.StillProcessing',
};
