import { Component, OnInit } from '@angular/core';
import { SplashService } from '../splash.service';
import { LocalStorage } from 'ngx-webstorage';
import { LocalStorageKeys } from '@webclient/settings/local-storage-keys';
import { SignInApiService } from '@api';
import { isValidBrandingUrl } from '@webclient/validators';
import { DefaultValidationErrorsComponent } from '../layout/default-validation-errors.component';
import { Show3cxLogoIconComponent } from './components/icons/show-3cx-logo-icon.component';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'standalone-view',
    template: `
    <default-validation-errors></default-validation-errors>
    <div class="login">
        <div class="container w-xxl">
            <div class="p-3 text-center">
                <a href="{{isValidBrandingUrl(brandUrl) ? brandUrl : ('_i18n.AboutUri' | translate)}}" target="_blank" class="d-block">
                    @if (brandImage) {
                        <img [src]="brandImage" class="brand-logo">
                    } @else {
                        <show-3cx-logo-icon class="logo standalone-logo"></show-3cx-logo-icon>
                    }
                </a>
            </div>
            <ng-content></ng-content>
        </div>
    </div>`,
    styleUrls: ['./standalone-view.component.scss'],
    standalone: true,
    imports: [DefaultValidationErrorsComponent, Show3cxLogoIconComponent, TranslatePipe]
})
export class StandaloneViewComponent implements OnInit {
    readonly isValidBrandingUrl = isValidBrandingUrl;

    @LocalStorage(LocalStorageKeys.BrandUrl, null)
    brandUrl?: string|null;

    @LocalStorage(LocalStorageKeys.BrandImage, null)
    brandImage?: string|null;

    constructor(private splashService: SplashService, private signInApiService: SignInApiService) { }

    ngOnInit() {
        this.signInApiService.signInBrandingOptions().subscribe({
            next: val => {
                this.brandImage = val.BrandingImageUrl;
                this.brandUrl = val.BrandUrl && isValidBrandingUrl(val.BrandUrl) ? val.BrandUrl : null;
                this.splashService.remove();
            },
            error: err => {
                // Ignore error here
                this.splashService.remove();
            }
        });
    }
}
