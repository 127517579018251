import type { AppContact } from './contact';
import type { ContactsMap } from './bridge';

export class SortedContactsMap {
    private contactsMap: ContactsMap = {};
    private sortedContacts: AppContact[] = [];

    sortIsNeeded = false;

    public setContact(contact: AppContact) {
        const currentContact = this.contactsMap[contact.id];
        this.contactsMap[contact.id] = contact;
        if (!this.sortIsNeeded) {
            this.sortIsNeeded = currentContact === undefined || currentContact.firstNameLastName !== contact.firstNameLastName;
        }
    }

    public removeContact(id: string|number) {
        const contact = this.contactsMap[id];
        delete this.contactsMap[id];

        if (!this.sortIsNeeded) {
            const index = this.sortedContacts.indexOf(contact);
            this.sortedContacts.splice(index, 1);
        }
    }

    public getSortedContacts(): AppContact[] {
        if (this.sortIsNeeded) {
            const contacts: AppContact[] = Object.values(this.contactsMap);
            this.sortedContacts = contacts.sort((c1, c2) => {
                if (c1.firstNameLastName > c2.firstNameLastName) {
                    return 1;
                }

                if (c1.firstNameLastName < c2.firstNameLastName) {
                    return -1;
                }

                if (c1.id > c2.id) {
                    return 1;
                }

                if (c1.id < c2.id) {
                    return -1;
                }

                return 0;
            });
            this.sortIsNeeded = false;
        }

        return this.sortedContacts;
    }

    public getContacts() {
        if (!this.sortIsNeeded) {
            return this.sortedContacts;
        }
        return Object.values(this.contactsMap);
    }

    // todo: REMOVE due new MyPhoneAPI
    public getContactById(id: string|number): AppContact {
        return this.contactsMap[id];
    }

    // todo: Remove. Implemented for compatibility reasons
    public internal(): ContactsMap {
        return this.contactsMap;
    }

    public getIds(): string[] {
        return Object.keys(this.contactsMap);
    }
}
