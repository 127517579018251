/* eslint-disable import/no-extraneous-dependencies */
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, throwError, combineLatest, of } from 'rxjs';
import { languageList } from '@webclient/lang.service';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import enAdminLang from '@L10n/admin.json';
import enLinks from '@L10n/links.json';
import { Injectable } from '@angular/core';

@Injectable()
export class BundleTranslateLoader implements TranslateLoader {
    constructor(private http: HttpClient) {

    }

    getTranslation(lang: string): Observable<any> {
        const language = languageList.find(value => value.id === lang);
        if (!language) {
            return throwError(() => new Error('Language not found'));
        }
        return combineLatest([enAdminLang, enLinks, ...language.data].map(data => (typeof data === 'string' ? this.http.get(data) : of(data)))).pipe(
            map(langs => langs.reduce((prev: any, curr: any) =>
                ({
                    _i18n: Object.assign(prev._i18n, curr._i18n)
                }),
            {
                _i18n: {}
            }))
        );
    }
}
