import { protoBufToJsDateTime } from '../../shared/utils.service';
import {
    ChatDeliveryStatus,
    ChatFile,
    ChatMessage as MyPhoneChatMessage,
    ChatMessageType,
    ChatRecipientRef
} from '@myphone';
import { List, Record } from 'immutable';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, } from 'rxjs';
import { processChatMessage } from './escape-chat-message';
import { escapeHtml } from '@webclient/chat/chat-service/escape';
// Chat icons
import imgTransfer from 'img/icons/chat-transfer.svg';
import imgTake from 'img/icons/take.svg';
import imgMute from 'img/icons/font-awesome/solid/comment-alt-slash.svg';
import imgUser from 'img/icons/font-awesome/solid/user-alt.svg';
import imgFile from 'img/icons/font-awesome/light/file-alt.svg';
import imgSentMessage from 'img/icons/font-awesome/solid/paper-plane.svg';
import imgDealtWith from 'img/icons/font-awesome/regular/check.svg';
import imgRemove from 'img/icons/font-awesome/light/times.svg';

export enum MessageDisplayClass {
    Standard = 'message-standard',
    System = 'message-system',
    Whisper = 'message-whisper'
}

export class ChatMessage extends Record(<{
    Id: number;
    IsNew: boolean;
    UnsafeMessage: string;
    Sended: Date;
    ShowDate: boolean;
    NewSender: boolean;
    Sender: number;
    NewDay: boolean;
    Attachment?: ChatFile;
    IdConversation: number;
    Participants: List<ChatRecipientRef>;
    MessageType: ChatMessageType;
    Param: string;
}>{
    Id: 0,
    IsNew: false,
    // Not processed message
    UnsafeMessage: '',
    ShowDate: false,
    Sended: new Date(),
    NewSender: false,
    Sender: 0,
    NewDay: false,
    Attachment: undefined,
    IdConversation: 0,
    Participants: List<ChatRecipientRef>(),
    MessageType: ChatMessageType.CMT_Normal,
    Param: '',
}) {
    // Message linkifed, scaped and emojified
    get messageLinkified() {
        const message = processChatMessage(this.UnsafeMessage, true);
        Object.defineProperty(this, 'messageLinkified', {
            get: () => message
        });

        return message;
    }

    // Message escaped and emojified
    get messageEmojified() {
        const message = processChatMessage(this.UnsafeMessage, false);
        Object.defineProperty(this, 'messageEmojified', {
            get: () => message
        });

        return message;
    }

    get showDeliveryStatus() {
        const isStatusVisible = this.MessageType === ChatMessageType.CMT_Normal;
        Object.defineProperty(this, 'showDeliveryStatus', {
            get: () => isStatusVisible
        });

        return isStatusVisible;
    }

    get messageDisplayClass() {
        let displayClass = MessageDisplayClass.Standard;
        // Show icon only if not showing delivery status to avoid multiple icons
        switch (this.MessageType) {
            case ChatMessageType.CMT_Whisper:
                displayClass = MessageDisplayClass.Whisper;
                break;
            case ChatMessageType.CMT_Blocked:
            case ChatMessageType.CMT_ClosedByProvider:
            case ChatMessageType.CMT_GenericError:
            case ChatMessageType.CMT_Closed:
            case ChatMessageType.CMT_AutoClosed:
            case ChatMessageType.CMT_Taken:
            case ChatMessageType.CMT_Muted:
            case ChatMessageType.CMT_Dealt:
            case ChatMessageType.CMT_Transferred:
            case ChatMessageType.CMT_ParticipantAdded:
            case ChatMessageType.CMT_ParticipantRemoved:
            case ChatMessageType.CMT_PersonInDnd:
            case ChatMessageType.CMT_MessageTooLong:
                displayClass = MessageDisplayClass.System;
                break;
        }

        Object.defineProperty(this, 'messageDisplayClass', {
            get: () => displayClass
        });

        return displayClass;
    }

    get messageIcon() {
        let icon: any;
        // Show icon only if not showing delivery status to avoid multiple icons
        switch (this.MessageType) {
            case ChatMessageType.CMT_Blocked:
                icon = imgRemove;
                break;
            case ChatMessageType.CMT_PersonInDnd:
            case ChatMessageType.CMT_MessageTooLong:
            case ChatMessageType.CMT_ClosedByProvider:
            case ChatMessageType.CMT_GenericError:
            case ChatMessageType.CMT_Closed:
            case ChatMessageType.CMT_AutoClosed:
                icon = imgRemove;
                break;
            case ChatMessageType.CMT_Muted:
                icon = imgMute;
                break;
            case ChatMessageType.CMT_Taken:
                icon = imgTake;
                break;
            case ChatMessageType.CMT_Dealt:
                icon = imgDealtWith;
                break;
            case ChatMessageType.CMT_Transferred:
                icon = imgTransfer;
                break;
            case ChatMessageType.CMT_ParticipantRemoved:
            case ChatMessageType.CMT_ParticipantAdded:
                icon = imgUser;
                break;
            default:
                if (this.Attachment) {
                    icon = imgFile;
                }
                break;
        }

        Object.defineProperty(this, 'messageIcon', {
            get: () => icon
        });

        return icon;
    }

    public get interlocutors() {
        return this.Participants.filter(participant => !participant.IsSender);
    }

    public get isFailure() {
        return this.interlocutors.some(part => part.Delivery === ChatDeliveryStatus.CDS_Failed);
    }

    public get isRead() {
        return this.interlocutors.every(part => part.IsRead);
    }

    public get messageMainStatus(): ChatDeliveryStatus {
        if (this.interlocutors.some(part => part.Delivery === ChatDeliveryStatus.CDS_Failed)) {
            return ChatDeliveryStatus.CDS_Failed;
        }
        else if (this.interlocutors.some(part => part.Delivery === ChatDeliveryStatus.CDS_NotDelivered)) {
            return ChatDeliveryStatus.CDS_NotDelivered;
        }
        else if (this.interlocutors.every(part => part.Delivery === ChatDeliveryStatus.CDS_Delivered)) {
            return ChatDeliveryStatus.CDS_Delivered;
        }
        else {
            return ChatDeliveryStatus.CDS_NotDelivered;
        }
    }

    constructor(item: MyPhoneChatMessage) {
        super({
            Id: item.Id,
            UnsafeMessage: item.Message,
            IsNew: item.IsNew,
            Sended: protoBufToJsDateTime(item.Time),
            Attachment: item.File ? new ChatFile(item.File) : undefined,
            Sender: item.Recipients?.find(x => x.IsSender)?.IdRecipient ?? -1,
            IdConversation: item.IdConversation,
            Participants: List<ChatRecipientRef>(item.Recipients),
            MessageType: item.MessageType,
            Param: item.Param
        });
    }
}

export function translateSystemMessageIcon(messageType: ChatMessageType): any {
    switch (messageType) {
        case ChatMessageType.CMT_Blocked:
            return imgRemove;
        case ChatMessageType.CMT_PersonInDnd:
        case ChatMessageType.CMT_MessageTooLong:
        case ChatMessageType.CMT_ClosedByProvider:
        case ChatMessageType.CMT_Closed:
        case ChatMessageType.CMT_AutoClosed:
            return imgRemove;
        case ChatMessageType.CMT_Taken:
            return imgTake;
        case ChatMessageType.CMT_Muted:
            return imgMute;
        case ChatMessageType.CMT_Dealt:
            return imgDealtWith;
        case ChatMessageType.CMT_Transferred:
            return imgTransfer;

        case ChatMessageType.CMT_ParticipantRemoved:
        case ChatMessageType.CMT_ParticipantAdded:
            return imgUser;

        default:
            return imgSentMessage;
    }
}

const knownSystemMessageTranslationTags: {[id: number]: string} = {
    [ChatMessageType.CMT_PersonInDnd]: '_i18n.ChatMessagePersonInDnd',
    [ChatMessageType.CMT_MessageTooLong]: '_i18n.ChatMessageMessageTooLong',
    [ChatMessageType.CMT_ClosedByProvider]: '_i18n.ChatMessageClosedByProvider',
    [ChatMessageType.CMT_Closed]: '_i18n.ChatMessageClosed',
    [ChatMessageType.CMT_AutoClosed]: '_i18n.ChatMessageAutoClosed',
    [ChatMessageType.CMT_Blocked]: '_i18n.ChatMessageBlocked',
    [ChatMessageType.CMT_Dealt]: '_i18n.ChatMessageDealt',
    [ChatMessageType.CMT_Taken]: '_i18n.ChatMessageTaken',
    [ChatMessageType.CMT_Muted]: '_i18n.ChatMessageMuted',
    [ChatMessageType.CMT_Transferred]: '_i18n.ChatMessageTransferredWithParams',
    [ChatMessageType.CMT_ParticipantAdded]: '_i18n.ChatParticipantAdded',
    [ChatMessageType.CMT_ParticipantRemoved]: '_i18n.ChatParticipantRemoved',
};

export function translateSystemMessages(translate: TranslateService, message: ChatMessage, messageText: string, escapeRequired: boolean): Observable<string> {
    const knownTag: any = knownSystemMessageTranslationTags[message.MessageType.valueOf()];
    if (knownTag) {
        if (message.MessageType === ChatMessageType.CMT_ParticipantAdded) {
            const parts = message.UnsafeMessage.split(' added ');
            const val = parts[1] ?? '';
            return translate.get(knownTag, {
                p1: escapeRequired ? escapeHtml(val) : val
            });
        }
        else if (message.MessageType === ChatMessageType.CMT_ParticipantRemoved) {
            const parts = message.UnsafeMessage.split(' removed ');
            const val = parts[1] ?? '';
            return translate.get(knownTag, {
                p1: escapeRequired ? escapeHtml(val) : val
            });
        }
        else if (message.MessageType === ChatMessageType.CMT_Transferred) {
            if (message.Param) {
                return translate.get(knownTag, {
                    p1: escapeRequired ? escapeHtml(message.Param) : message.Param
                });
            }
            else {
                return translate.get('_i18n.ChatMessageTransferred');
            }
        }
        else {
            return translate.get(knownTag);
        }
    }
    else {
        return of(message.Attachment ? messageText || (escapeRequired ? escapeHtml(message.Attachment.FileName) : message.Attachment.FileName) : messageText);
    }
}

export const defaultMessage = new ChatMessage(new MyPhoneChatMessage({
    Message: ''
}));
