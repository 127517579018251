import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class ThemeDomService {
    private _doc: Document;
    constructor(@Inject(DOCUMENT) document: Document) {
        this._doc = document;
    }

    applyApplicationTheme(theme: string) {
        this._doc.body.setAttribute('data-theme', theme);
    }
}
