import { Injectable } from '@angular/core';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { switchMap } from 'rxjs/operators';
import { PbxAvatarStyle } from '@xapi';
import { map, Observable } from 'rxjs';
import { publishRef } from '@webclient/rx-share-utils';

@Injectable({
    providedIn: 'root'
})
export class BrandingService {
    public AvatarStyle$: Observable<string>;

    constructor(private myPhoneService: MyPhoneService) {
        this.AvatarStyle$ = this.myPhoneService.myPhoneSession.pipe(
            switchMap(session => session.systemParameters$),
            map(systemParameters => systemParameters.AvatarStyle),
            publishRef()
        );
    }

    attachAvatarStyleClassToBody(avatarStyle: string) {
        // remove first existing avatar-style- class
        document.body.className = document.body.classList.value.split(' ').filter((cssClass) => cssClass && !cssClass.toLowerCase().startsWith('avatar-style-')).join(' ');
        document.body.classList.add(`avatar-style-${(avatarStyle ?? PbxAvatarStyle.Square).toLowerCase()}`);
    }
}
