import { Component } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StandaloneViewComponent } from '../shared/standalone-view.component';
import { TranslateDirective } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'no-content',
    template: `
        <standalone-view>
            <div class="text-center">
                <h2 class="mb-4 themeColor" [translate]="'_i18n.PageMissing'">
                    <span>{{errorCode$ | async}}</span>
                    <span>{{ statusText$ | async }}</span>
                </h2>
                <a routerLink="/people" class="dashed_link" [translate]="'_i18n.Home'">Home</a>
            </div>
        </standalone-view>
  `,
    styles: ['.themeColor {color: var(--text-secondary);}'],
    standalone: true,
    imports: [StandaloneViewComponent, TranslateDirective, RouterLink, AsyncPipe]
})
export class ErrorPageComponent {
    public readonly errorCode$: Observable<string>;
    public readonly statusText$: Observable<string>;
    constructor(
        private activatedRoute: ActivatedRoute,
    ) {
        this.errorCode$ = activatedRoute.queryParamMap.pipe(map(params => params.get('code') || ''));
        this.statusText$ = activatedRoute.queryParamMap.pipe(map(params => params.get('desc') || ''));
    }
}
