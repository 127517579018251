import { Pipe, PipeTransform } from '@angular/core';
import { convertServerErrors, translateError } from './convert-server-error.utils';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'translateServerError',
    standalone: true
})
export class TranslateServerErrorPipe implements PipeTransform {
    constructor(private translate: TranslateService) {
    }

    transform(value: string | null | undefined): string {
        return value ? translateError(convertServerErrors[value] || value, this.translate) : '';
    }
}
