import { NoContentComponent } from './no-content';
import { MyphoneSessionComponent } from './myphone-session-component';
import { ErrorPageComponent } from '@webclient/error-page/error-page.component';
import { Route } from '@angular/router';

export const ROUTES: Route[] = [
    {
        path: 'login',
        loadComponent: () => import('./login/wc-login.component').then(m => m.WcLoginComponent),
    },
    {
        path: 'reset',
        loadComponent: () => import('./reset-password/reset-password.component').then(m => m.ResetPasswordComponent),
    },
    {
        path: 'forgot',
        loadComponent: () => import('./forgot-password/forgot-password.component').then(m => m.ForgotPasswordComponent),
    },
    {
        path: 'app/settings/office365',
        redirectTo: 'office/integrations/microsoft365'
    },
    {
        path: 'app/settings/google',
        redirectTo: 'office/integrations/google'
    },
    { path: 'tel', redirectTo: '/' },
    { path: 'error', component: ErrorPageComponent },
    {
        // Note this will be loaded always for any unknown route
        path: '',
        component: MyphoneSessionComponent,
        children: [
            {
                path: 'call',
                loadComponent: () => import('./call-adapter/standalone-dialer.component').then(m => m.StandaloneDialerComponent),
            },
            {
                path: 'dialer',
                loadComponent: () => import('./call-adapter/standalone-dialer.component').then(m => m.StandaloneDialerComponent),
                data: {
                    title: '_i18n.Dialer'
                }
            },
            {
                path: 'wallboard',
                data: {
                    fullScreen: true
                },
                loadChildren: () => import('./wallboard/wallboard.module').then(m => m.wallboardRoutes),
            },
            {
                path: 'reports',
                loadChildren: () => import('./admin/reports-print/reports-print.module').then(m => m.reportsRoutes),
            },
            {
                path: 'ActiveCalls',
                data: {
                    fullScreen: true
                },
                loadChildren: () => import('@webclient/switchboard/active-calls/active-calls.module').then(m => m.activeCallsRoutes),
            },
            {
                path: 'queue_calls',
                data: {
                    fullScreen: true
                },
                loadChildren: () => import('@webclient/switchboard/queues/all-queues-calls.module').then(m => m.allQueueCallsRoutes)
            },
            {
                path: 'receptionist',
                data: {
                    fullScreen: true
                },
                loadChildren: () => import('@webclient/switchboard/receptionist/receptionist.module').then(m => m.receptionistRoutes)
            },
            {
                path: '',
                loadChildren: () => import('./layout.routes').then(m => m.LAYOUT_ROUTES)
            }
        ]
    },
    { path: '**', component: NoContentComponent },
];
