import { Record } from 'immutable';

export class Profile extends Record({
    name: '',
    internalName: '',
    // Current from myphone message
    status: '',

    fwdType: <any>undefined,
    awayNumber: ''
}) {

}

export type ProfileNames = 'Available'| 'Away'| 'Out of office'| 'Custom 1'| 'Custom 2'| 'off'| 'Exceptions';

export const ProfileNames = {
    Available: 'Available',
    Away: 'Away',
    OutOfOffice: 'Out of office',
    Custom1: 'Custom 1',
    Custom2: 'Custom 2',
    Unregistered: 'off',
    Exceptions: 'Exceptions'
} as const;

export function isProfileRegistered(profile: Partial<Profile>) {
    return profile.internalName !== ProfileNames.Unregistered;
}

export function getCustomProfileName(name: string, userCustomName: string, systemParameters: { Custom1Name?: string | null, Custom2Name?: string | null }): string {
    if (userCustomName) {
        return userCustomName;
    }

    // User didn't specify custom name
    if (name === ProfileNames.Custom1 && !!systemParameters.Custom1Name) {
        return systemParameters.Custom1Name;
    }
    else if (name === ProfileNames.Custom2 && !!systemParameters.Custom2Name) {
        return systemParameters.Custom2Name;
    }
    else {
        return name;
    }
}
