import { ContactOptionMask, DnType, RequestExactMatch, RequestLookupContact, SearchByMask } from '@myphone';
import { AnnotatedResult, AnnotatedResultType } from '@webclient/shared/search/anotated-result';
import { AppContactType } from '@webclient/myphone/app-contact-type';
import { contactToChatAddress } from '@webclient/chat-data/chat-utils';
import { Record } from 'immutable';

export class SearchContext extends Record({
    request: <Readonly<RequestLookupContact>>(new RequestLookupContact()),
    useNativeMobile: false,
    vMailCode: <string|undefined>undefined,
    converter: <((result: AnnotatedResult) => AnnotatedResult)|undefined>undefined,
    resultFilter: <((result: AnnotatedResult) => boolean)|undefined>undefined
}) {
    private readonly SearchByMask = SearchByMask;

    public isInValidForNumberRequst(input: string) {
        return input.indexOf('*') >= 0;
    }

    public contextRequestHasEmail() {
        return ((this.request.SearchBy & SearchByMask.SBM_Email) === SearchByMask.SBM_Email);
    }

    public contextRequestHasCRM() {
        return this.request.SearchCompany || this.request.SearchPersonal;
    }

    public get exactMatchType(): RequestExactMatch {
        return new RequestExactMatch({
            DnType: this.request.SysExtsMask,
            SearchCompany: Boolean(this.request.SearchCompany),
            SearchBridge: Boolean(this.request.SearchBridge),
            SearchPersonal: Boolean(this.request.SearchPersonal)
        });
    }

    static get allDnTypes(): number {
        return DnType.SpecialMenu | DnType.Service | DnType.RingGroup |
            DnType.Queue | DnType.Parking | DnType.IVR |
            DnType.Fax | DnType.ExternalLine | DnType.Extension |
            DnType.Conference;
    }

    static get forwardingTypes(): number {
        return SearchContext.allDnTypes ^ DnType.Parking ^ DnType.ExternalLine ^ DnType.Service;
    }

    static get fullSearch(): number {
        return SearchByMask.SBM_Company | SearchByMask.SBM_ExtNumber |
            SearchByMask.SBM_OtherNumber | SearchByMask.SBM_Email |
            SearchByMask.SBM_FirstName | SearchByMask.SBM_LastName;
    }

    static personalPhonebook() {
        return new SearchContext({
            request: new RequestLookupContact({
                ExtIncluded: false,
                SearchBy: SearchContext.fullSearch,
                SearchPersonal: true,
                SearchCompany: false,
                IncludePersonalOptions: ContactOptionMask.CO_3CX,
            }),
            resultFilter: (result: AnnotatedResult) => {
                return true;
            }
        });
    }

    static companyPhonebook() {
        return new SearchContext({
            request: new RequestLookupContact({
                ExtIncluded: false,
                SearchBy: SearchContext.fullSearch,
                SearchPersonal: false,
                SearchCompany: true,
                IncludeCompanyOptions: ContactOptionMask.CO_3CX,
            }),
            resultFilter: (result: AnnotatedResult) => {
                return true;
            }
        });
    }

    static crmPhonebook() {
        return new SearchContext({
            request: new RequestLookupContact({
                ExtIncluded: false,
                SearchBy: SearchContext.fullSearch,
                SearchPersonal: false,
                SearchCompany: true,
                IncludeCompanyOptions: ContactOptionMask.CO_CRM,
            }),
            resultFilter: (result: AnnotatedResult) => {
                return true;
            }
        });
    }

    static office365Phonebook() {
        return new SearchContext({
            request: new RequestLookupContact({
                ExtIncluded: false,
                SearchBy: SearchContext.fullSearch,
                SearchPersonal: true,
                SearchCompany: true,
                IncludePersonalOptions: ContactOptionMask.CO_M365,
                IncludeCompanyOptions: ContactOptionMask.CO_M365,
            }),
            resultFilter: (result: AnnotatedResult) => {
                return true;
            }
        });
    }

    static googlePhonebook() {
        return new SearchContext({
            request: new RequestLookupContact({
                ExtIncluded: false,
                SearchBy: SearchContext.fullSearch,
                SearchPersonal: true,
                SearchCompany: false,
                IncludePersonalOptions: ContactOptionMask.CO_GOOGLE,
                IncludeCompanyOptions: ContactOptionMask.CO_GOOGLE,
            }),
            resultFilter: (result: AnnotatedResult) => {
                return true;
            }
        });
    }

    static allPhonebook(hideCrmContacts = false) {
        return new SearchContext({
            request: new RequestLookupContact({
                ExtIncluded: false,
                SearchBy: SearchContext.fullSearch,
                IncludePersonalOptions: ContactOptionMask.CO_GOOGLE | ContactOptionMask.CO_3CX | ContactOptionMask.CO_M365 | (hideCrmContacts ? 0 : ContactOptionMask.CO_CRM),
                IncludeCompanyOptions: ContactOptionMask.CO_GOOGLE | ContactOptionMask.CO_3CX | ContactOptionMask.CO_M365 | (hideCrmContacts ? 0 : ContactOptionMask.CO_CRM),
                SearchPersonal: true,
                SearchCompany: true,
            }),
            resultFilter: (result: AnnotatedResult) => {
                return true;
            }
        });
    }

    static allPersons() {
        return new SearchContext({
            request: new RequestLookupContact({
                ExtIncluded: true,
                SearchBy: SearchContext.fullSearch,
                SearchBridge: true,
                SearchPersonal: true,
                SearchCompany: true,
            }),
            resultFilter: (result: AnnotatedResult) => {
                return true;
            }
        });
    }

    static default() {
        const sc = new SearchContext({
            request: new RequestLookupContact({
                SearchBridge: true,
                SearchPersonal: true,
                SearchCompany: true,
                ExtIncluded: true,
                SysExtsMask: SearchContext.forwardingTypes,
                SearchBy: SearchContext.fullSearch
            }),
            resultFilter: (result: AnnotatedResult) => {
                return result.type !== AnnotatedResultType.email;
            }
        });
        return sc;
    }

    static allEmails() {
        return new SearchContext({
            request: new RequestLookupContact({
                SearchBridge: true,
                SearchPersonal: true,
                SearchCompany: true,
                ExtIncluded: true,
                SysExtsMask: DnType.Extension,
                Count: 25,
                SearchBy: SearchByMask.SBM_Email,
                MustHaveEmail: true,
            }),
            resultFilter: (result: AnnotatedResult) => {
                return result.type === AnnotatedResultType.email;
            }
        });
    }

    static headerSearch() {
        // Not email
        return new SearchContext({
            // SearchByMask.SBM_FirstName | SearchByMask.SBM_LastName  | SearchByMask.SBM_Company | SearchByMask.SBM_Email | SearchByMask.SBM_Number,
            request: new RequestLookupContact({
                SearchBridge: true,
                SearchPersonal: true,
                SearchCompany: true,
                ExtIncluded: true,
                SysExtsMask: SearchContext.allDnTypes ^ DnType.ExternalLine,
                SearchBy: SearchContext.fullSearch
            }),
            resultFilter: (result: AnnotatedResult) => {
                return result.type !== AnnotatedResultType.email && result.type !== AnnotatedResultType.voicemail;
            }
        });
    }

    static forwardingExtAndSystemExt() {
        return new SearchContext({
            request: new RequestLookupContact({
                SearchBridge: false,
                SearchPersonal: false,
                SearchCompany: false,
                ExtIncluded: true,
                SysExtsMask: SearchContext.forwardingTypes | DnType.Service,
                // issue: https://mantis.corp.3cx.com/view.php?id=38354 Last name is needed:
                // For  callflow apps the number is an alphanumeric text (e.g "_1_init_makecall.Main") therefore the search term on the back end is sanitized and returns nothing.
                // Solution:Number is copied into the last name by myphone as well to overcome this problem
                SearchBy: SearchByMask.SBM_ExtNumber | SearchByMask.SBM_LastName
            }),
            resultFilter: (result: AnnotatedResult) => {
                return result.type === AnnotatedResultType.internalNumber || result.type === AnnotatedResultType.voicemail;
            }
        });
    }

    static forwardingSystemExtensions() {
        return new SearchContext({
            request: new RequestLookupContact({
                SearchBridge: false,
                SearchPersonal: false,
                SearchCompany: false,
                ExtIncluded: false,
                SearchBy: SearchContext.fullSearch ^ SearchByMask.SBM_OtherNumber,
                SysExtsMask: SearchContext.forwardingTypes
            }),
            resultFilter: (result: AnnotatedResult) => {
                return result.type === AnnotatedResultType.internalNumber || result.type === AnnotatedResultType.voicemail;
            }
        });
    }

    static onlyLocalExtension() {
        return new SearchContext({
            request: new RequestLookupContact({
                SearchBridge: false,
                SearchPersonal: false,
                SearchCompany: false,
                ExtIncluded: true,
                SysExtsMask: DnType.Extension,
                SearchBy: SearchContext.fullSearch ^ SearchByMask.SBM_OtherNumber
            }),

            resultFilter: (result: AnnotatedResult) => {
                return result.type === AnnotatedResultType.internalNumber;
            }
        });
    }

    static favoritesEnabled() {
        return new SearchContext({
            request: new RequestLookupContact({
                SearchBridge: false,
                SearchPersonal: false,
                SearchCompany: false,
                ExtIncluded: true,
                SysExtsMask: DnType.Extension,
                SearchBy: SearchContext.fullSearch ^ SearchByMask.SBM_OtherNumber
            }),

            resultFilter: (result: AnnotatedResult) => {
                return result.type === AnnotatedResultType.internalNumber;
            }
        });
    }

    static chatEnabled(includeSelfContact: boolean) {
        return new SearchContext({
            request: new RequestLookupContact({
                SearchBridge: true,
                SearchPersonal: false,
                SearchCompany: false,
                ExtIncluded: true,
                SysExtsMask: DnType.Extension,
                SearchBy: SearchContext.fullSearch ^ SearchByMask.SBM_OtherNumber
            }),

            converter: (r: AnnotatedResult) => {
                if (r.type === AnnotatedResultType.internalNumber) {
                    return new AnnotatedResult(AnnotatedResultType.chatAddress, r.contact, r.searchText, contactToChatAddress(r.contact));
                }
                return new AnnotatedResult(AnnotatedResultType.none, r.contact);
            },
            resultFilter: (result: AnnotatedResult) => {
                return result.type === AnnotatedResultType.chatAddress && (includeSelfContact || !result.contact?.myContact);
            }
        });
    }

    static addToChatContext(includeSelfContact: boolean, includeQueue = true) {
        const base = SearchContext.chatEnabled(includeSelfContact);
        return base.merge({
            request: new RequestLookupContact({
                ...base.request,
                SysExtsMask: includeQueue ? base.request.SysExtsMask ^ DnType.Queue : base.request.SysExtsMask,
                SearchBridge: false })
        });
    }

    static forPrivateConference() {
        return new SearchContext({
            request: new RequestLookupContact({
                SearchBridge: true,
                SearchPersonal: true,
                SearchCompany: true,
                ExtIncluded: true,
                SysExtsMask: DnType.Extension,
                SearchBy: SearchContext.fullSearch
            }),
            resultFilter: (result: AnnotatedResult) => {
                return (result.type !== AnnotatedResultType.email) && (result.type !== AnnotatedResultType.voicemail);
            }
        });
    }

    static forAudioConference() {
        return new SearchContext({
            request: new RequestLookupContact({
                SearchBridge: true,
                SearchPersonal: true,
                SearchCompany: true,
                ExtIncluded: true,
                SysExtsMask: DnType.Extension,
                SearchBy: SearchContext.fullSearch
            }),
            // Hide emails for extensions
            resultFilter: (result: AnnotatedResult) => {
                const contactType = result.contact.type; // Shortcut
                const isExtension = (contactType === AppContactType.Extension || contactType === AppContactType.SystemExtension);

                // Reject voicemails and emails of extensions
                return !(result.type === AnnotatedResultType.voicemail) && !(isExtension && result.type === AnnotatedResultType.email);
            }
        });
    }

    static forSmsChat() {
        return new SearchContext({
            useNativeMobile: true,
            request: new RequestLookupContact({
                ExtIncluded: true,
                SearchBridge: true,
                SearchPersonal: true,
                SearchCompany: true,
                MustHaveMobile: true,
                SearchBy: SearchContext.fullSearch ^ SearchByMask.SBM_ExtNumber
            }),

            resultFilter: (result: AnnotatedResult) => {
                return result.type === AnnotatedResultType.externalNumber;
            }
        });
    }

    static forVideoAndAudioConference() {
        return new SearchContext({
            request: new RequestLookupContact({
                ExtIncluded: true,
                SearchBridge: true,
                SearchPersonal: true,
                SearchCompany: true,
                MustHaveEmail: true,
                SearchBy: SearchContext.fullSearch
            }),
            // Show only emails
            resultFilter: (result: AnnotatedResult) => {
                return result.type === AnnotatedResultType.email;
            },
            converter: (r: AnnotatedResult) => {
                if (r.contact.emailAddress) {
                    return new AnnotatedResult(AnnotatedResultType.email, r.contact, r.searchText, r.contact.emailAddress);
                }

                return new AnnotatedResult(AnnotatedResultType.none, r.contact);
            }
        });
    }
}
