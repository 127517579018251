import { Injectable } from '@angular/core';
import { concat, defer, Observable, of, race, ReplaySubject, Subject, switchMap, tap, throwError } from 'rxjs';
import { AuthResponse, TokenApiService, TokenResponse } from '@api';
import { delay, map, share, take } from 'rxjs/operators';
import { appName } from '@webclient/worker/fingerprint';
import { LocalStorageService } from 'ngx-webstorage';

export const msInMinute = 60000;

const ACCESS_TOKEN_KEY = 'AccessToken';
const DO_NOT_CACHE_ACCESS_TOKEN_KEY = 'DoNotCacheAccessToken';

@Injectable({
    providedIn: 'root'
})
export class TokenService {
    readonly accessToken$: Observable<string>;
    // switching off token cache until issue is finally resolved either by removing functionality of adjusting backend
    // main reason: token is linked to license and user rights and keeps old info in case of any changes on backend
    // consequences:
    // 1) license changing on backend is not noticed by frontend until cache expires (without cache F5 helps)
    // 2) pbx update may change user rights, update notification on top does not help to update client fully cause of token cache
    // possible solutions to consider:
    // a) add information about license and pbx version to access token, and verify it on each request with token
    // b) have some event from backend about token invalidation + check cached token on webclient initialization
    private readonly doNotCacheToken: boolean = true; // Boolean(this.storageService.retrieve(DO_NOT_CACHE_ACCESS_TOKEN_KEY));
    private readonly _invalidate$ = new Subject<string>();

    constructor(private tokenApi: TokenApiService, private storageService: LocalStorageService) {
        this.accessToken$ = defer(() => this.getToken$()).pipe(
            switchMap(accessToken => {
                if (accessToken?.access_token) {
                    return concat(
                        of(accessToken?.access_token),
                        race(
                            of('expired').pipe(delay(this.getExpirationMs(accessToken))),
                            this._invalidate$
                        ).pipe(
                            switchMap(err => throwError(() => new Error(err)))
                        )
                    );
                }
                else {
                    return throwError(() => new Error('Server returned empty access token'));
                }
            }),
            share({
                connector: () => new ReplaySubject<string>(1),
                resetOnComplete: false,
                resetOnRefCountZero: false
            }),
            // Just in case to indicate we expect only one emit from here
            take(1)
        );

        if (this.doNotCacheToken) {
            return;
        }
        // watch token change from another browser tab
        this.storageService.observe(ACCESS_TOKEN_KEY)
            .subscribe(() => this._invalidate$.next('cacheUpdate'));
    }

    /* creates special access token with 1 minute expiration */
    getShortAccessToken$() {
        return this.tokenApi.tokenToken('refresh_token', appName, undefined, undefined, undefined, 1)
            .pipe(map(token => token.access_token));
    }

    public onLogin(response: AuthResponse|undefined): void {
        this.cachedToken = response?.Token ?? null;
    }

    public invalidate(): void {
        this._invalidate$.next('logout');
        if (this.doNotCacheToken) {
            return;
        }
        this.storageService.clear(ACCESS_TOKEN_KEY);
    }

    private getExpirationMs(token: TokenResponse) {
        // expire token 1 minute prior to real expiration, or half expiration time if token expires in 1 minute
        return Math.max(token.expires_in / 2, token.expires_in - 1) * msInMinute;
    }

    private getToken$(): Observable<TokenResponse> {
        const token = this.cachedToken;

        return token
            ? of(token)
            : this.tokenApi.tokenToken('refresh_token', appName).pipe(tap(token => this.cachedToken = token));
    }

    private get cachedToken(): TokenResponse | null {
        if (this.doNotCacheToken) {
            return null;
        }
        try {
            const accessTokenString = this.storageService.retrieve(ACCESS_TOKEN_KEY);
            const token = accessTokenString ? JSON.parse(accessTokenString) as TokenResponse & { t?: number } : null;

            if (token?.access_token && token.t && token.t + this.getExpirationMs(token) > new Date().getTime()) {
                return token;
            }
            return null;
        }
        catch {
            return null;
        }
    }

    private set cachedToken(value: TokenResponse | null) {
        if (this.doNotCacheToken) {
            return;
        }
        if (value?.access_token) {
            // this will invalidate token in other tabs
            // and also in current tab, but we read it on refresh from local storage
            // invalid token is not cleared from storage to avoid unnecessary invalidates
            this.storageService.store(ACCESS_TOKEN_KEY, JSON.stringify({ ...value, t: new Date().getTime() }));
        }
    }
}
