import { Component } from '@angular/core';
import { MaxCertificateFileSize } from '@webclient/validators';
import { FileSizePipe } from '@webclient/standalones/pipes';
import { ValdemortModule } from 'ngx-valdemort';
import { TranslatePipe } from '@ngx-translate/core';
import { TranslateServerErrorPipe } from '@webclient/convert-server-error';

@Component({
    selector: 'default-validation-errors',
    template: `
        <val-default-errors>
            <ng-template valError="startWithError" let-error="error">{{'_i18n.FTPPathSupportedProtocols' | translate: error}}</ng-template>
            <ng-template valError="invalidTime">{{'_i18n.DefaultErrorsInvalidTime' | translate}}</ng-template>
            <ng-template valError="stopDateBeforeStart">{{'_i18n.DefaultErrorsStopDateBeforeStart' | translate}}</ng-template>
            <ng-template valError="stopTimeBeforeStart">{{'_i18n.DefaultErrorsStopTimeBeforeStart' | translate}}</ng-template>
            <ng-template valError="mobileNumberIsInvalid">{{'_i18n.MobileNotValid' | translate}}</ng-template>
            <ng-template valError="email">{{'_i18n.InvalidEmail' | translate}}</ng-template>
            <ng-template valError="emailList">{{ '_i18n.DefaultErrorsInvalidEmailList'  | translate}}</ng-template>
            <ng-template valError="required" let-label>
                {{label ? (label.toString() | translate) + ' ' + ('_i18n.IsRequired' | translate) : ('_i18n.FieldIsRequired' | translate)}}
            </ng-template>
            <ng-template valError="firstNameOrLastNameRequired">{{'_i18n.DefaultErrorsFirstNameOrLastNameRequired' | translate}}</ng-template>
            <ng-template valError="invalidPhoneNumber">{{'_i18n.InvalidPhoneNumber' | translate}}</ng-template>
            <ng-template valError="invalidMobile">{{'_i18n.InvalidMobile' | translate}}</ng-template>
            <ng-template valError="invalidHost">{{'_i18n.DefaultErrorsInvalidHost' | translate}}</ng-template>
            <ng-template valError="invalidIPAddress">{{'_i18n.DefaultErrorsInvalidIPAddress' | translate}}</ng-template>
            <ng-template valError="invalidXml">{{'_i18n.DefaultErrorsInvalidXml' | translate}}</ng-template>
            <ng-template valError="minlength" let-error="error">{{'_i18n.ValueCannotBeLessThan' | translate: {p1: error.requiredLength} }}</ng-template>
            <ng-template valError="maxlength" let-error="error">{{'_i18n.ValueCannotBeMoreThan' | translate: {p1: error.requiredLength} }}</ng-template>
            <ng-template valError="pattern">{{ '_i18n.InvalidFormat' | translate }}</ng-template>
            <ng-template valError="invalidNumber">{{ '_i18n.DefaultErrorsInvalidNumber' | translate }}</ng-template>
            <ng-template valError="invalidInteger">{{'_i18n.DefaultErrorsInvalidInteger' | translate}}</ng-template>
            <ng-template valError="invalidMacAddress">{{'_i18n.InvalidMacAddress' | translate}}</ng-template>
            <ng-template valError="min" let-error="error">{{'_i18n.DefaultErrorsMinNumber' | translate: {min: error.min} }}</ng-template>
            <ng-template valError="max" let-error="error">{{'_i18n.DefaultErrorsMaxNumber' | translate: {max: error.max} }}</ng-template>
            <ng-template valError="range" let-label="label" let-error="error">
                {{label ? (label.toString() | translate) + ': ' : ''}}{{'_i18n.AllowedValuesFrom' | translate: { from: error.min, to: error.max } }}
            </ng-template>
            <ng-template valError="invalidDate">{{'_i18n.InvalidDateFormat' | translate}}</ng-template>
            <ng-template valError="outOfRange">{{'_i18n.XapiOutOfRange' | translate}}</ng-template>
            <ng-template valError="listElementNotFound">{{'_i18n.ListElementNotFound' | translate}}</ng-template>
            <ng-template valError="duplicateMembers">{{'_i18n.ListHasDuplicateMembers' | translate}}</ng-template>
            <ng-template valError="invalidDialCode">{{'_i18n.InvalidDialCode' | translate}}</ng-template>
            <ng-template valError="invalidAlphanumeric">{{'_i18n.InvalidAlphanumeric' | translate}}</ng-template>
            <ng-template valError="invalidHexCharacter">{{'_i18n.InvalidHexCharacter' | translate}}</ng-template>
            <ng-template valError="unique">{{'_i18n.XapiInUse' | translate}}</ng-template>
            <ng-template valError="invalidUrl">{{'_i18n.InvalidUrl' | translate}}</ng-template>
            <ng-template valError="invalidExtensionNumber">{{ '_i18n.InvalidExtensionNumber' | translate }}</ng-template>
            <ng-template valError="noAvailableExtensionNumbers">{{ '_i18n.NoAvailableExtensionNumbers' | translate }}</ng-template>
            <ng-template valError="dateInThePast">{{'_i18n.DateOrTimeCantBeInThePast' | translate}}</ng-template>

            <ng-template valError="invalid">{{'_i18n.XapiInvalid' | translate}}</ng-template>
            <ng-template valError="invalidRange">{{'_i18n.InvalidRange' | translate}}</ng-template>
            <ng-template valError="password">{{'_i18n.PasswordWeak' | translate}}</ng-template>
            <ng-template valError="passwordWithSpecialChars">{{'_i18n.PasswordWeakWithSpecialChars' | translate}}</ng-template>
            <ng-template valError="passwordMismatch">{{'_i18n.PasswordMismatch' | translate }}</ng-template>
            <ng-template valError="duplicate">{{'_i18n.XapiDuplicate' | translate}}</ng-template>
            <ng-template valError="notFound">{{'_i18n.XapiNotFound' | translate}}</ng-template>
            <ng-template valError="invalidCredentials">{{'_i18n.XapiInvalidCredentials' | translate}}</ng-template>
            <ng-template valError="notSupported">{{ '_i18n.XapiNotSupported' | translate}}</ng-template>

            <ng-template valError="fileTooBig">{{'_i18n.ChatsFileExceedsMaxSize' | translate}}</ng-template>

            <ng-template valError="xapiError" let-error="error">{{ error | translateServerError }}</ng-template>
            <ng-template valError="customError" let-error="error">{{ (error.message ?? error) | translate: error.translateParams }}</ng-template>

            <!-- Certificate specific errors -->
            <ng-template valError="invalidCertificateFormat">{{'_i18n.InvalidCertificateFileFormat' | translate}}</ng-template>
            <ng-template valError="fileNameSize">{{'_i18n.FileNameCannotBeMoreThan50' | translate}}</ng-template>
            <ng-template valError="certificateSize">{{'_i18n.FileSizeLimit' | translate : { p1: (MaxCertificateFileSize | fileSize) } }}</ng-template>
        </val-default-errors>
    `,
    standalone: true,
    imports: [FileSizePipe, ValdemortModule, TranslatePipe, TranslateServerErrorPipe]
})
export class DefaultValidationErrorsComponent {
    protected readonly MaxCertificateFileSize = MaxCertificateFileSize;
}
