import {
    BehaviorSubject,
    combineLatest,
    EMPTY,
    from,
    fromEvent,
    Observable,
    of,
    ReplaySubject,
    Subject,
    throwError,
    zip
} from 'rxjs';
import { catchError, filter, finalize, map, scan, share, switchMap, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { extractErrorMessage, ModalService } from '../modal/app-modal.service';
import { ModalButtons, ModalResult } from '../modal/message-box';
import { MyPhoneService } from '../myphone/myphone.service';
import { dummyMediaDescription, MediaDescription } from '../phone/media-description';
import * as SDPUtils from 'sdp';
import { MyCall } from '../phone/mycall';
import {
    BargeInMode,
    MyWebRTCEndpoint,
    RequestWebRTCChangeSDPState,
    ResponseWebRTCChangeSDPState,
    WebRTCCall,
    WebRTCEndpointSDPState,
    WebRTCHoldState
} from '@myphone';
import { controlStream, onSubscribe } from './control-stream';
import { DeviceMediaService } from '../phone/device-media.service';
import { maybePreferCodec } from '@webclient/webrtc/sdp-funcs';
import { LocalStorage } from 'ngx-webstorage';
import { LocalStorageKeys } from '@webclient/settings/local-storage-keys';
import { List } from 'immutable';
import { setupMediaForPeerConnection, stopStream } from '@webclient/webrtc/media-tools';
import { HeadsetService } from '@webclient/phone/headsets/headset.service';
import { finalizeWithValue } from '@webclient/rx-utils';

function patchFirefoxSendrecInSession(sdp: string, resume: boolean) {
    if (resume) {
        return sdp;
    }
    return sdp.replace('sendrecv', 'sendonly');
}

function setupPeerConnection(media: MediaDescription) {
    function teardown(media: MediaDescription) {
        if (media.peerConnection) {
            media.peerConnection.close();
        }
        media.audio = undefined;
        media.isVideoReceived = false;
        media.toneSend$ = EMPTY;
        media.video = undefined;
        media.remoteStream$.next(null);
    }

    teardown(media);

    media.peerConnection = new RTCPeerConnection({});
    media.peerConnection.ontrack = (event) => media.remoteStream$.next(event.streams[0]);
    return media.peerConnection;
}

function remoteEndpointState(sdp: string | null, direction: string[]) {
    let audio = false;
    let video = false;
    if (sdp) {
        SDPUtils.splitSections(sdp)
            .filter((section: any) => direction.indexOf(SDPUtils.getDirection(section, '')) >= 0 && !SDPUtils.isRejected(section))
            .map((allowedSection: any) => SDPUtils.getKind(allowedSection))
            .forEach((kind: string) => {
                if (kind === 'video') {
                    video = true;
                }
                else if (kind === 'audio') {
                    audio = true;
                }
            });
    }
    return [audio, video];
}

/**
 * @param {string} sdp Other party sdp
 * @returns {[boolean , boolean]} True if other party will receive corresponding media
 */
export function remoteEndpointReceiveState(sdp: string | null) {
    return remoteEndpointState(sdp, ['sendrecv', 'recvonly']);
}

export function remoteEndpointSendState(sdp: string | null) {
    return remoteEndpointState(sdp, ['sendrecv', 'sendonly']);
}

/**
 * Return media for a call
 * @param {MyCall} call
 * @param {MediaDescription[]} endpoint
 * @returns {MediaDescription} Media if found, dummy media otherwise
 */
export function findWebRTCCallState(call: MyCall, endpoint: MediaDescription[]) {
    return endpoint.find(x => !!call.SIPDialogID && call.SIPDialogID?.includes(x.lastWebRTCState.SIPDialogID)) || dummyMediaDescription;
}

function setRemoteDescription(peerConnection: RTCPeerConnection, description: RTCSessionDescriptionInit) {
    return from(peerConnection.setRemoteDescription(description));
}

/**
 * Destroy media streams and connection
 * @param {MediaDescription} media
 */
export function destroyMedia(media?: MediaDescription) {
    if (!media) {
        return;
    }
    if (media.localStream) {
        stopStream(media.localStream);
    }
    // Close negotiations
    // Avoid race condition when call under negotiation is being dropped
    if (!media.isNegotiationInProgress && media.peerConnection) {
        media.peerConnection.close();
    }
}
@Injectable({
    providedIn: 'root'
})
export class WebRTCControlService {
    @LocalStorage(LocalStorageKeys.WebRtcCodecPriority, undefined)
    private webRtcCodecPriority?: string[];

    public readonly mediaDevice$: Observable<MediaDescription[]>;
    public readonly webRTCEndpoint$: Observable<MyWebRTCEndpoint>;

    private _lastInboundMedia?: MediaDescription;
    private _lastOutboundMedia?: MediaDescription;

    private _globalTransactionId = 0;
    public readonly _forcedEmit = new BehaviorSubject(true);
    private readonly _suspendStream = new Subject<void>();
    private readonly _resumeStream = new Subject<void>();

    constructor(private modalService: ModalService,
                private headsetService: HeadsetService,
                private deviceService: DeviceMediaService,
                private myPhoneService: MyPhoneService) {
        this.webRTCEndpoint$ = this.myPhoneService.myPhoneSession.pipe(switchMap(session => session.webRTCEndpoint$), controlStream(this._suspendStream, this._resumeStream));
        this.mediaDevice$ = combineLatest([this.webRTCEndpoint$, this._forcedEmit]).pipe(
            scan((mediaDevice, values) => {
                // TODO WTF?
                const [endpoint] = values;
                const stateMap: { [id: number]: WebRTCCall } = endpoint.Items.reduce((result: { [id: number]: WebRTCCall }, item: any) => {
                    result[item.Id] = item;
                    return result;
                }, {});

                if (this._lastOutboundMedia && stateMap[this._lastOutboundMedia.lastWebRTCState.Id]) {
                    // Seems we have outgoing call and it's processed
                    mediaDevice.push(this._lastOutboundMedia);
                    this._lastOutboundMedia = undefined;
                }

                if (this._lastInboundMedia && stateMap[this._lastInboundMedia.lastWebRTCState.Id]) {
                    // Seems we have outgoing call and it's processed
                    mediaDevice.push(this._lastInboundMedia);
                    this._lastInboundMedia = undefined;
                }

                const updatedMedia: MediaDescription[] = [];
                mediaDevice.forEach(media => {
                    const newWebRTCState = stateMap[media.lastWebRTCState.Id];
                    if (!newWebRTCState) {
                        destroyMedia(media);
                    }
                    else {
                        const lastWebRTCState = media.lastWebRTCState;
                        // Copy new state there
                        media.lastWebRTCState = { ...newWebRTCState };
                        /* Warning! This is a patch in case HELD call goes ESTABLISHED when inactive. We force hold immediately */
                        if (!media.isActive && newWebRTCState.holdState === WebRTCHoldState.WebRTCHoldState_NOHOLD &&
                            lastWebRTCState.holdState === WebRTCHoldState.WebRTCHoldState_HELD) {
                            // We switched from hold to nohold on active media
                            this.hold(media, false).subscribe({
                                error: (err: unknown) => {
                                    console.warn(err);
                                }
                            });
                        }

                        if (!(lastWebRTCState.sdpType === newWebRTCState.sdpType && lastWebRTCState.sdp === newWebRTCState.sdp)) {
                            this.processState(lastWebRTCState.sdpType, media).subscribe({
                                error: (err: unknown) => {
                                    if (extractErrorMessage(err) !== 'Call is not found') {
                                        this.modalService.error(err);
                                    }
                                }
                            });
                        }

                        delete stateMap[newWebRTCState.Id];
                        updatedMedia.push(media);
                    }
                });

                return updatedMedia; // .concat(newMedia);
            }, <MediaDescription[]>[]),
            finalizeWithValue((value) => value.forEach(val => destroyMedia(val))),
            share({ connector: () => new ReplaySubject<MediaDescription[]>(1) })
        );
    }

    setLocalDescription(peerConnection: RTCPeerConnection, description: RTCSessionDescriptionInit, reorderCodecs: boolean) {
        return this.myPhoneService.myPhoneSession.pipe(
            map(session => session.systemParameters),
            take(1),
            switchMap(parameters => {
                if (reorderCodecs) {
                    const webRtcCodecPriority = this.webRtcCodecPriority ?? parameters.WebRtcCodecs;
                    if (webRtcCodecPriority?.length > 0) {
                        // Adjust codec priorities
                        webRtcCodecPriority.slice().reverse().forEach(codec => {
                            if (description.sdp) {
                                description.sdp = maybePreferCodec(description.sdp, 'audio', codec);
                            }
                        });
                    }
                }
                return from(peerConnection.setLocalDescription(description)).pipe(
                    switchMap(() => fromEvent(peerConnection, 'icegatheringstatechange')),
                    filter(() => peerConnection.iceGatheringState === 'complete'),
                    take(1));
            }));
    }

    public processState(
        currentEndpointState: WebRTCEndpointSDPState,
        media: MediaDescription,
    ): Observable<any> {
        if (!media.peerConnection) {
            return EMPTY;
        }

        switch (media.lastWebRTCState.sdpType) {
            case WebRTCEndpointSDPState.WRTCAnswerProvided:
                return this.processAnswerProvided(currentEndpointState, media);

            case WebRTCEndpointSDPState.WRTCOffer:
                return this.processOffer(media);

            case WebRTCEndpointSDPState.WRTCRequestForOffer:
                return this.processRequestForOffer(media);

            case WebRTCEndpointSDPState.WRTCConfirmed:
                this.processConfirmed(media);
                break;
        }

        return EMPTY;
    }

    private processConfirmed(media: MediaDescription) {
        media.isNegotiationInProgress = false;
        if (media.peerConnection?.remoteDescription) {
            const [_, remoteSendsVideo] = remoteEndpointSendState(media.peerConnection.remoteDescription.sdp);
            media.isVideoReceived = remoteSendsVideo;
        }
    }

    private processAnswerProvided(currentEndpointState: WebRTCEndpointSDPState, media: MediaDescription) {
        const callState = media.lastWebRTCState;

        // Achtung: This is a real call answer when call was already answered using early media
        // in this situation simply go to confirmed state
        if (currentEndpointState === WebRTCEndpointSDPState.WRTCConfirmed) {
            return this.setConfirmed(callState.Id);
        }

        const [_, remoteWantsVideo] = remoteEndpointReceiveState(media.lastWebRTCState.sdp);
        if (!remoteWantsVideo && media.video) {
            // Video is transmitted but not needed at all
            // This causes NS_ERROR_UNEXPECTED in Firefox only
            // media.peerConnection.removeTrack(media.video);
            if (media.localStream) {
                media.localStream.getVideoTracks().forEach(track => track.stop());
            }
            media.video = undefined;
        }

        // / We send offer and remote party currently provided us with an answer
        return setRemoteDescription(media.peerConnection, {
            type: 'answer',
            sdp: callState.sdp
        })
            .pipe(switchMap(() => this.setConfirmed(callState.Id)));
    }

    private setConfirmed(Id: number) {
        return this.requestChangeState({ Id, sdpType: WebRTCEndpointSDPState.WRTCConfirm });
    }

    private processOffer(media: MediaDescription) {
        // If the call will be held by other party
        const [_, remoteSendsVideo] = remoteEndpointSendState(media.lastWebRTCState.sdp);

        // Ask a user if he wants video in a call
        if (!media.isVideoCall && remoteSendsVideo) {
            return this.modalService.messageBox('_i18n.IfAnswerWithVideo', '_i18n.ConfirmationRequired', undefined, ModalButtons.OkCancel).pipe(
                switchMap(({ decision: modalResult }) => {
                    media.isVideoCall = modalResult === ModalResult.Ok;
                    return this.processAnswer(media);
                })
            );
        }
        else {
            return this.processAnswer(media);
        }
    }

    private processRequestForOffer(media: MediaDescription) {
        return this.processAnswer(media);
    }

    public getLastOutgoingMedia() {
        const temp = this._lastOutboundMedia;
        this._lastOutboundMedia = undefined;
        return temp;
    }

    public getLastInboundMedia() {
        const temp = this._lastInboundMedia;
        this._lastInboundMedia = undefined;
        return temp;
    }

    private holdAll(exceptId?: number) {
        return this.mediaDevice$.pipe(
            take(1),
            map(devices => devices.filter(device => device.lastWebRTCState.Id !== exceptId)),
            switchMap(devices => (devices.length ? zip(...devices.map(media => this.hold(media, false))) : of([]))));
    }

    public pickupCall(replaceConnectionId: number, myCallId: number, isVideoCall: boolean): Observable<any> {
        return this.setupCall(isVideoCall, /* myCallId, */ {
            replaceConnectionId
        }).pipe(tap(media => {
            this._lastOutboundMedia = media;
            this.headsetService.outgoingCall(myCallId, media.lastWebRTCState);
        }));
    }

    public makeCall(destinationNumber: string, myCallId: number, isVideoCall: boolean): Observable<any> {
        return this.setupCall(isVideoCall, /* myCallId, */ {
            destinationNumber
        }).pipe(tap(media => {
            this._lastOutboundMedia = media;
            this.headsetService.outgoingCall(myCallId, media.lastWebRTCState);
        }));
    }

    public makeBargeInCall(replaceConnectionId: number, myCallId: number, destinationNumber: BargeInMode): Observable<any> {
        return this.setupCall(false, /* myCallId, */ {
            replaceConnectionId,
            destinationNumber: BargeInMode[destinationNumber],
        }).pipe(tap(media => {
            this._lastOutboundMedia = media;
            this.headsetService.outgoingCall(myCallId, media.lastWebRTCState);
        }));
    }

    private setupCall(isVideoCall: boolean, params: Partial<RequestWebRTCChangeSDPState>): Observable<MediaDescription> {
        const media = new MediaDescription(/* myCallId, */ {
            lastWebRTCState: new WebRTCCall({
                sdpType: WebRTCEndpointSDPState.WRTCInitial,
                holdState: WebRTCHoldState.WebRTCHoldState_NOHOLD
            }),
        });
        media.isActive = true;
        media.isNegotiationInProgress = true;
        media.isVideoCall = isVideoCall;
        const peerConnection = setupPeerConnection(media);
        return this.holdAll().pipe(
            switchMap(() => setupMediaForPeerConnection(media, true, isVideoCall, this.deviceService.mediaPeerConstraints$)),
            tap(() => {
                this.deviceService.permissionsRequested$.next(true);
            }),
            switchMap(localStream => from(peerConnection.createOffer({
                // Receive audio enabled
                offerToReceiveAudio: true,
                // Receive video enabled
                offerToReceiveVideo: isVideoCall
            }))),
            switchMap(localDescription => this.setLocalDescription(peerConnection, localDescription, true)),
            switchMap(() => ((peerConnection.localDescription && peerConnection.localDescription.sdp) ?
                this.requestChangeState({
                    Id: 0,
                    sdpType: WebRTCEndpointSDPState.WRTCOffer,
                    transactionId: this._globalTransactionId++,
                    sdp: peerConnection.localDescription.sdp,
                    ...params
                }, true) :
                throwError(() => new Error('Local sdp missing')))),
            map((x: ResponseWebRTCChangeSDPState) => {
                media.lastWebRTCState = new WebRTCCall({
                    Id: x.CallId,
                    sdpType: WebRTCEndpointSDPState.WRTCInitial
                });
                return media;
            }),
            catchError((err: unknown) => {
                destroyMedia(media);
                return throwError(() => err);
            }));
    }

    public answer(media: MediaDescription, answerWithVideo: boolean) {
        if (media.isNegotiationInProgress) {
            return EMPTY;
        }
        media.isActive = true;
        media.isVideoCall = answerWithVideo;
        return this.holdAll(media.lastWebRTCState.Id).pipe(switchMap(() => this.processAnswer(media)));
    }

    private processAnswer(media: MediaDescription) {
        const callState = media.lastWebRTCState;
        const peerConnection = setupPeerConnection(media);
        if (!media.isActive) {
            // Mute media in case somebody makes renegotiation on inactive call
            media.isMuted = true;
        }
        media.isNegotiationInProgress = true;

        let bootstrap$: Observable<Event>;
        let sdpType: WebRTCEndpointSDPState;
        if (callState.sdpType === WebRTCEndpointSDPState.WRTCOffer) {
            if (!callState.sdp) {
                return throwError(() => new Error('Offer doesn\'t have sdp'));
            }
            // If the call will be held by other party
            const [remoteWantsAudio, remoteWantsVideo] = remoteEndpointReceiveState(callState.sdp);
            // Provide answer
            sdpType = WebRTCEndpointSDPState.WRTCAnswer;
            bootstrap$ = setupMediaForPeerConnection(media, remoteWantsAudio, remoteWantsVideo && media.isVideoCall, this.deviceService.mediaPeerConstraints$).pipe(
                tap(() => this.deviceService.permissionsRequested$.next(true)),
                switchMap(() => setRemoteDescription(peerConnection, {
                    type: 'offer',
                    sdp: callState.sdp
                })),
                switchMap(() => from(peerConnection.createAnswer())),
                switchMap(localDescription => this.setLocalDescription(peerConnection, localDescription, false)),
            );
        }

        else if (callState.sdpType === WebRTCEndpointSDPState.WRTCRequestForOffer) {
            // Create offer
            sdpType = WebRTCEndpointSDPState.WRTCOffer;
            const offerOptions: RTCOfferOptions = {
                offerToReceiveAudio: true,
                offerToReceiveVideo: media.isVideoCall
            };
            bootstrap$ = setupMediaForPeerConnection(media, true, media.isVideoCall, this.deviceService.mediaPeerConstraints$).pipe(
                tap(() => this.deviceService.permissionsRequested$.next(true)),
                switchMap(() => from(peerConnection.createOffer(offerOptions))),
                switchMap(localDescription => this.setLocalDescription(peerConnection, localDescription, true)),
            );
        }
        else {
            media.isNegotiationInProgress = false;
            return throwError(() => new Error(`Can't answer when state ${callState.sdpType}`));
        }

        return bootstrap$.pipe(
            switchMap(() => ((peerConnection.localDescription && peerConnection.localDescription.sdp) ? this.requestChangeState({
                Id: callState.Id,
                sdpType,
                transactionId: callState.transactionId,
                sdp: peerConnection.localDescription.sdp
            }) : throwError(() => new Error('Local sdp missing')))),
            catchError((err: unknown) => {
                media.isNegotiationInProgress = false;
                return throwError(() => err);
            }));
    }

    public associateCalls(myCalls: List<MyCall>, mediaDevice: MediaDescription[]) {
        const unassociatedCalls = mediaDevice.reduce((acc, media) => {
            acc[media.lastWebRTCState.SIPDialogID] = media;
            return acc;
        }, {} as {[id: string]: MediaDescription});

        myCalls.forEach(call => {
            call.media = findWebRTCCallState(call, mediaDevice);
            call.isWebRtcBrowserAgent = call.media !== dummyMediaDescription;
            call.SIPDialogID?.forEach(dialog => {
                delete unassociatedCalls[dialog];
            });
        });
        return List(Object.values(unassociatedCalls));
    }

    public sendDtmf(media: MediaDescription, code: string) {
        if (media.audio && media.audio.dtmf) {
            media.audio.dtmf.insertDTMF(code, 100, 100);
        }
    }

    public video(media: MediaDescription) : Observable<any> {
        if (media.isVideoCall && (!media.isVideoReceived || !media.isVideoSend)) {
            // We're not receiving any video so let's renegotiate with video again
        }
        else {
            media.isVideoCall = !media.isVideoCall;
        }
        return this.renegotiate(media, true);
    }

    public mute(media: MediaDescription, myCallId: number) {
        this.setMute(media, !media.isMuted);
        this.headsetService.mute(myCallId, media.isMuted);
    }

    private setMute(media: MediaDescription, mute: boolean) {
        media.isMuted = mute;
        if (media.audio && media.audio.track) {
            media.audio.track.enabled = !mute;
        }
    }

    public hold(media: MediaDescription, resume: boolean): Observable<any> {
        media.isActive = resume;
        const callState = media.lastWebRTCState;

        // Mute call anyway
        this.setMute(media, !resume);

        // Hold requested and we're not in a proper state
        if (!resume && callState.holdState !== WebRTCHoldState.WebRTCHoldState_NOHOLD) {
            return of(true);
        }

        // Resume requested and we're not in a proper state
        if (resume && callState.holdState !== WebRTCHoldState.WebRTCHoldState_HOLD) {
            // This call is ok but please make sure others are on hold here
            return this.holdAll(media.lastWebRTCState.Id);
        }

        return this.renegotiate(media, resume).pipe(
            catchError((err: unknown) => {
                // You tried to hold a call that doesn't exist - this is ok
                if (extractErrorMessage(err) === 'Call is not found') {
                    console.warn(err);
                    return of(true);
                }
                else {
                    return throwError(() => err);
                }
            })
        );
    }

    public reactivate(media: MediaDescription) {
        return this.renegotiate(media, true);
    }

    private renegotiate(media: MediaDescription, resume: boolean): Observable<boolean|ResponseWebRTCChangeSDPState> {
        // Something is already going on but at least we muted
        if (media.isNegotiationInProgress) {
            return of(true);
        }
        const callState = media.lastWebRTCState;

        media.isNegotiationInProgress = true;
        this._forcedEmit.next(true);
        const peerConnection = setupPeerConnection(media);

        let bootstrap$: Observable<any> = of(true);
        if (resume) {
            // All others should go on hold
            bootstrap$ = this.holdAll(media.lastWebRTCState.Id);
        }

        return bootstrap$.pipe(
            switchMap(() => setupMediaForPeerConnection(media, true, resume ? media.isVideoCall : false, this.deviceService.mediaPeerConstraints$)),
            tap(() => this.deviceService.permissionsRequested$.next(true)),
            switchMap(() => from(peerConnection.createOffer({
                offerToReceiveAudio: resume,
                offerToReceiveVideo: resume && media.isVideoCall
            }))),
            switchMap(localDescription => this.setLocalDescription(peerConnection, localDescription, true)),
            switchMap(() => ((peerConnection.localDescription && peerConnection.localDescription.sdp) ? this.requestChangeState(({
                Id: callState.Id,
                sdpType: WebRTCEndpointSDPState.WRTCOffer,
                transactionId: this._globalTransactionId++,
                sdp: patchFirefoxSendrecInSession(peerConnection.localDescription.sdp, resume)
            })) : throwError(() => new Error('Local sdp missing')))),
            catchError((err: unknown) => {
                media.isNegotiationInProgress = false;
                // In any case we replaced PeerConnection and it means the call is invalid anyway
                // so let's just close newly created PeerConnection on error here
                destroyMedia(media);
                this._forcedEmit.next(true);
                return throwError(() => err);
            }));
    }

    public drop(myCall: MyCall) {
        return this.myPhoneService.get(new RequestWebRTCChangeSDPState({
            Id: myCall.media.lastWebRTCState.Id,
            sdpType: WebRTCEndpointSDPState.WRTCTerminate
        }));
    }

    public requestChangeState(init: Partial<RequestWebRTCChangeSDPState>, suspend?: boolean) {
        return suspend ?
            this.myPhoneService.get<ResponseWebRTCChangeSDPState>(new RequestWebRTCChangeSDPState(init)).pipe(
                onSubscribe(() => this._suspendStream.next()),
                switchMap(response => (response.Success ? of(response) : throwError(() => new Error(response.Message)))),
                finalize(() => this._resumeStream.next())) :
            this.myPhoneService.get<ResponseWebRTCChangeSDPState>(new RequestWebRTCChangeSDPState(init)).pipe(
                switchMap(response => (response.Success ? of(response) : throwError(() => new Error(response.Message)))));
    }
}
