import { Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import type { FullMediaTrack, SelectedConstraints, SelectedMediaDevice } from './device-media.service';
import { MyPhoneService } from '@webclient/myphone/myphone.service';

export function isLocalWebRtcRegistered(myphone: MyPhoneService) {
    return myphone.myPhoneSession.pipe(switchMap(x =>
        x.webRTCEndpoint$.pipe(map(y => y.isWebRTCEnpointRegistered))
    ), distinctUntilChanged());
}

export function blockedByLength(source: Observable<any[]>): Observable<boolean> {
    return source.pipe(map(arr => !arr || arr.length === 0));
}

export function wrapNoDefault(source: Observable<SelectedMediaDevice>): Observable<string | null> {
    return source.pipe(map(device => {
        if (!device || !device.media) {
            return null;
        }
        else {
            return device.media.label;
        }
    }));
}

export function mapSelectedDevices(isAudioCall: boolean, isVideoCall: boolean): (source: Observable<SelectedConstraints>) => Observable<FullMediaTrack> {
    return (source: Observable<SelectedConstraints>): Observable<FullMediaTrack> =>
        source.pipe(map(constraints => {
            const audioRequest = isAudioCall ? (constraints.mic ? { deviceId: constraints.mic } : isAudioCall) : (isAudioCall);
            const videoRequest = isVideoCall ? (constraints.cam ? { deviceId: constraints.cam } : isVideoCall) : (isVideoCall);
            return {
                audio: audioRequest,
                video: videoRequest
            };
        }));
}
